import CountdownBlack from '../../assets/fundo-countdownblack.png'

import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;
    padding: 2rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: transparent;

    @media (max-width: 479px) {
        padding: 1rem;
    }
`

export const ContainerBlack = styled.div` 
    width: 100%;
    height: auto;
    max-width: 1140px;
    padding: 48px;
    
    gap: 2rem;
    display: flex;
    align-items: center;
    
    border-radius: 1rem;
    background-color: #FF5252; 

    background-size: auto;
    background-position: -410px -70px;
    background-image: url(${CountdownBlack});

    @media (max-width: 900px) {
        flex-direction: column;

        background-position: -515px -70px;
    }

    @media (max-width: 767px) {
        padding: 32px;
        
        background-position: -645px -70px;
    }

    @media (max-width: 479px) {
        padding: 16px;
    }
`

export const InformationBlack = styled.div` 
    width: 100%;
    height: auto;

    gap: 1.5rem;
    display: flex;
    flex-direction: column;

    h1, p, span {
        color: #fff;
        font-family: "Lato", Sans-serif;
    } 

    >h1{
        font-size: 36px;
        font-weight: 800;
        line-height: 1.3em;
        }

    >p{  
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5em;
    }

    >span{
        font-size: 10px;
        font-weight: 700;
        line-height: 1.5em;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    @media (max-width: 900px) {
        align-items: center;
    }

    @media (max-width: 479px) {
        gap: 1rem;

        h1, p, span {
            text-align: center;
        } 

        >h1{
            font-size: 28px;
        }

        >p{  
            font-size: 16px;
        }

        >span{
            font-size: 7px;
        }
    }
`

export const CountdownAndButton = styled.div` 
    width: 100%;
    height: auto;

    gap: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;

    >a >button {
        all: unset;

        width: auto;
        height: auto;
        max-width: 15rem;
        padding: 15px 18px;

        display: flex;
        justify-content: center;

        
        border-radius: 50px;
        background-color: #21262F;
        border: 1px solid #21262F;

        >p{
            cursor: pointer;
            
            color: #fff;
            font-size: 10px;
            font-weight: 800;
            line-height: 1.5em;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-family: "Lato", Sans-serif;
        }
    }

    @media (max-width: 479px) {
        gap: 26px;
    }
`

export const ContainerCountdown = styled.div` 
    width: 100%;
    height: auto;

    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 320px) {
        gap: 0.5rem;
    }
`

export const ContainerBlock = styled.div` 
    width: 120px;
    height: auto;
    padding: 20px 0;

    display: flex;
    align-items: center;
    flex-direction: column;

    border-radius: 1rem;
    background-color: #FFF;

    >span:nth-of-type(1){
        color: #21262F;
        font-size: 48px;
        font-weight: 800;
        line-height: 1.2em;
        font-family: "Lato", Sans-serif;
    }

    >span:nth-of-type(2){
        color: #21262F;
        font-size: 11px;
        font-weight: 700;
        line-height: 1.5em;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: "Lato", Sans-serif;
    }

    @media (max-width: 479px) {
        padding: 0;
        height: 3.8rem;

        justify-content: center;

        >span:nth-of-type(1){
            font-size: 32px;
            line-height: 32px;
        }

        >span:nth-of-type(2){
            font-size: 8px;
            line-height: 11px;
        }
    }

    @media (max-width: 320px) {
        >span:nth-of-type(1){
            font-size: 26px;
            line-height: 26px;
        }

        >span:nth-of-type(2){
            font-size: 7px;
        }
    }
`

export const HeadContainer = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`

export const HeaderPromo= styled.div`
    width: 100%;
    height: auto;
    padding: 10px 3rem 10px 1rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    background-color: #FF5252; 

    > a > button{
        all: unset;

        width: 11rem;
        height: auto;
        padding: 1.125rem 1.313rem;

        display: flex;
        justify-content: center;

        cursor: pointer;
        border-radius: 3.125rem;
        background-color: #21262F;
        border: 1px solid #21262F;

        >p{
            color: #fff;
            font-weight: 800;
            line-height: 1.5em;
            font-size: 0.625rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-family: "Lato", Sans-serif;
        }
    }

    >button {
        right: 12px !important;
        position: absolute !important;

        all: unset;
        width: auto;
        height: auto;
        cursor: pointer;
    }

    @media (max-width: 1024px) {
        padding: 0.625rem 2.5rem 0.625rem 1rem;

        > a > button{
            width: 9rem;
            padding: 0.525rem 0.638rem;

            >p{
                text-align: center;

                font-size: 0.525rem;
            }
        }

        >button{
            right: 6px !important;
        }
    }

    @media (max-width: 880px) {
        height: auto;
    }

    @media (max-width: 767px) {
        padding: 5px 3rem 8px 1rem;

        gap: 0.5rem;
        flex-direction: column;

        >a > button {
            padding: 8px 16px;
         
            >p{
                text-align: center;
            }
        }

        >button{
            top: 12px;
            right: 12px !important;
        }
    }

    @media (max-width: 479px) {
        padding: 2.3rem 1rem 0.3rem 1rem;

        >button{
            top: 9px;
            right: 12px !important;
        }
    }

    @media (max-width: 320px) {
        >button{
            right: 7px !important;
        }
    }
`

export const HeaderTimer= styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    >span{
        color: #21262F;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5em;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: "Lato", Sans-serif;
    }

    @media (max-width: 1024px) {
        gap: 0.5rem;

        >span{
            font-size: 12px;
            line-height: 1.5em;
        }
    }

    @media (max-width: 760px) {
        width: 100%;
    }
`

export const HeaderContainerCountdown = styled.div` 
    width: auto;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 760px) {
        width: 100%;
    }
`

export const HeaderContainerBlock = styled.div` 
    width: 4.5rem;
    height: auto;
    padding: 8px;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #ffff;

    >span:nth-of-type(1){
        color: #21262F;
        font-family: "Lato", Sans-serif;
        font-size: 16px;
        font-weight: 800;
        line-height: 1.2em;
    }

    >span:nth-of-type(2){
        color: #21262F;
        font-family: "Lato", Sans-serif;
        font-size: 8px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.5em;
        letter-spacing: 1px;
    }

    @media (max-width: 1024px) {
        width: 60px;
    }

    @media (max-width: 760px) {
        width: 100%;
    }
`

export const HeadDescription = styled.div` 
    width: auto;
    height: auto;
    padding: 0 10px;

    >p{
        width: 100%;
        max-width: 540px;

        color: #FFFFFF;
        font-family: "Lato", Sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        font-style: normal;
        text-decoration: none;
        line-height: 1.5em;
    }

    @media (max-width: 1024px) {
        >p {
            width: 100%;
            max-width: 540px;


            font-size: 10px;
        }
    }

    @media (max-width: 760px) {
        padding: 0;

        >p {
            width: 100%;
            max-width: 100%;

            text-align: center;
        }
    }
`