import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import { Helmet } from 'react-helmet';

import Footer from '../../components/footer';
import Carousel from '../../components/carousel';
import PlanTable from '../../components/planTable';
import QualityTable from '../../components/qualityTable';
import CountdownBlack from '../../components/countdown-black';
import StepsAndPreview from '../../components/stepsAndPreview';
import AnimatedCounter from '../../components/animated-counter';
import CarouselComments from '../../components/carousel-comments';
import RegistrationAndLogin from '../../components/registrationAndLogin';

import * as S from './styles';

export const compendioG = "@compendioG";

const ReceituarioAgronomico2024 = ({ ...props }) => {
    const [isBlackNovemberVisible, setIsBlackNovemberVisible] = useState(false);

    const handleBlackNovemberVisibility = (isVisible) => {
        setIsBlackNovemberVisible(isVisible);
    };

    return (
        <>
            <Helmet>
                <title>Receituário Agronômico 2024</title>
                <meta
                    name="description"
                    content="Descubra as melhores opções de planos e serviços no Receituário Agronômico 2024. Qualidade garantida para o sucesso do seu agronegócio!"
                />
                <meta name="keywords" content="Receituário Agronômico 2024" />
                <meta name="author" content="AgroReceita" />
                <meta property="og:title" content="Receituário Agronômico 2024" />
                <meta property="og:description" content="Explore nossos serviços e planos de qualidade para o agronegócio no Receituário Agronômico 2024." />
                <meta property="og:url" content="https://sistema.agroreceita.com.br/receituario-agronomico-2024" />
            </Helmet>

            <S.Container>
                <S.Wrapper>
                    <RegistrationAndLogin />

                    <Carousel />

                    <CountdownBlack onVisibilityChange={handleBlackNovemberVisibility}/>

                    <StepsAndPreview />

                    <AnimatedCounter />

                    <CarouselComments />

                    <QualityTable />

                    <PlanTable />

                    <S.ContainerFooter>
                        <Footer />
                    </S.ContainerFooter>
                </S.Wrapper>
            </S.Container>
        </>
    );
};

export default withRouter(ReceituarioAgronomico2024);
