import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import 'animate.css';
import Countdown from 'react-countdown';

import * as S from './styles';

export const compendioG = "@compendioG";

const CountdownBlack = ({ onVisibilityChange, ...props }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [isVisible, setIsVisible] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const currentDate = new Date();
    const targetDate = new Date('2024-11-29T23:59:59');

    useEffect(() => {
        onVisibilityChange(isVisible);
    }, [isVisible, onVisibilityChange]);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Evento encerrado!</span>;
        } else {
            return (
                <S.ContainerCountdown>
                    <S.ContainerBlock>
                        <span>{days}</span>

                        <span>dias</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{hours}</span>

                        <span>horas</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{minutes}</span>

                        <span>minutos</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{seconds}</span>

                        <span>segundos</span>
                    </S.ContainerBlock>
                </S.ContainerCountdown>
            );
        }
    };

    if (!isVisible || currentDate > targetDate) return null;

    if (currentPath === "/black-november-2024" || currentPath === "/receituario-agronomico-2024") {
        const containerClass = isAnimating ? 'animate__animated animate__fadeOutUp' : '';

        return (
            <S.Container className={containerClass}>
                <S.Wrapper>
                    <S.ContainerBlack>
                        <S.InformationBlack>
                            <h1>Black November</h1>
                            <p>
                                Utilize o cupom <b>AGROBLACK</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro.
                            </p>
                            <span>Promoção válida para novos clientes até 29 de novembro de 2024.</span>
                        </S.InformationBlack>

                        <S.CountdownAndButton>
                            <Countdown date={targetDate} renderer={renderer} />

                            <a href="#">
                                <button>
                                    <p>Garantir meu desconto</p>
                                </button>
                            </a>
                        </S.CountdownAndButton>
                    </S.ContainerBlack>
                </S.Wrapper>
            </S.Container>
        );
    }

    return null;
};

export default CountdownBlack;