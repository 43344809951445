import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 2rem;

    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 2px solid #F3F3F5;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ContainerSubscribeAndUser = styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const SubscribeButton = styled.button`
    all: unset;

    width: 6.25rem;
    height: 1.25rem;
    padding: 0.25rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border-radius: 2rem;
    background-color: #589BFF;

    > p {
        cursor: pointer;

        color: #FFFFFF;
        font-weight: 800;
        font-size: 0.5625rem;
        letter-spacing: 0.1rem;
        line-height: 0.84375rem;
        font-family: "Lato", Sans-serif;
    }

    :hover {
        opacity: 0.7;
    }
`;

export const UserName = styled.div`
    width: auto;
    height: auto;

    > h4 {
        color: #21262F;
        font-weight: 800;
        font-size: 1.75rem;
        line-height: 2.275rem;
        font-family: "Lato", Sans-serif;

        @media (max-width: 479px) {
            font-size: 1rem;
        }
    }
`;

export const ContainerNotificationAndProfile = styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;
`;

export const ContainerNotification = styled.button`
    all: unset;

    width: 3rem;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        width: 1.25rem;
        height: 1.25rem;

        cursor: pointer;
        border-radius: 10rem;
    }
`;

export const Profile = styled.div`
    width: auto;
    height: auto;
    padding: 0.3rem 0.5rem 0.3rem 0.3rem;

    gap: 0.5rem;
    display: flex;
    align-items: center;

    border-radius: 10rem;
    background-color: #F3F3F5;

    > img {
        width: 2rem;
        height: 2rem;

        border-radius: 10rem;
    }

    > p {
        text-transform: uppercase;

        color: #21262F;
        font-weight: 800;
        font-size: 0.5625rem;
        letter-spacing: 0.1rem;
        line-height: 0.84375rem;
        font-family: "Lato", Sans-serif;
    }
`;

export const ButtonProfile = styled.button`
    all: unset;

    display: flex;
    align-items: center;

    cursor: pointer;
`;