import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #FFFF;

    >div{
        width: 100%;
    }
`;