export const NewCardNotificationData = [
    {
        id: 1,
        type:"bug",
        time:"Hoje",
        alert: "Clima ideal para aplicação",
        localization: "Fazenda Teste"
    },
    {
        id: 2,
        type:"leaf",
        time:"Amanhã",
        alert: "Alta chance de Requeima",
        localization: "Fazenda Teste"
    },
    {
        id: 3,
        type:"fruit",
        time:"Próximos 7 dias",
        alert: "Clima ruim para colheita",
        localization: "Fazenda Teste"
    },
    {
        id: 4,
        type:"leaf",
        time:"Próximos 7 dias",
        alert: "Alta chance de Requeima",
        localization: "Fazenda Teste"
    },
    {
        id: 5,
        type:"fruit",
        time:"Próximos 7 dias",
        alert: "Clima ruim para colheita",
        localization: "Fazenda Teste"
    },
    {
        id: 6,
        type:"leaf",
        time:"Próximos 7 dias",
        alert: "Alta chance de Requeima",
        localization: "Fazenda Teste"
    },
    {
        id: 7,
        type:"fruit",
        time:"Próximos 7 dias",
        alert: "Clima ruim para colheita",
        localization: "Fazenda Teste"
    }
]