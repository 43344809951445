import React, { useRef } from 'react';

import IconBug from '../../../assets/icons/icon-new-card-notification-bug.svg'
import IconLeaf from '../../../assets/icons/icon-new-card-notification-leaf.svg'
import IconFruit from '../../../assets/icons/icon-new-card-notification-fruit.svg'
import IconLocalization from '../../../assets/icons/icon-new-card-notification-localization.svg'

import * as S from './styles';

import { NewCardNotificationData } from './NewCardNotificationData';

export const compendioG = "@compendioG";

const NewCardNotification = () => {
    const wrapperRef = useRef(null);

    const handleMouseDown = (e) => {
        const wrapper = wrapperRef.current;
        let startX = e.pageX - wrapper.offsetLeft;
        let scrollLeft = wrapper.scrollLeft;

        const onMouseMove = (event) => {
            const x = event.pageX - wrapper.offsetLeft;
            const walk = x - startX;
            wrapper.scrollLeft = scrollLeft - walk;
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    return (
        <S.Container>
            <S.Wrapper ref={wrapperRef} onMouseDown={handleMouseDown}>
                {NewCardNotificationData.map((item) => (
                    <S.CardNotification key={item.id}>
                        <S.ImageAndNotification>
                            <S.ContainerImage>
                                <img
                                    src={
                                        item.type === 'bug'
                                            ? IconBug
                                            : item.type === 'leaf'
                                            ? IconLeaf
                                            : IconFruit
                                    }
                                    alt="Status"
                                />
                            </S.ContainerImage>

                            <S.Notification>
                                <span>{item.time}</span>
                                <p>{item.alert}</p>
                            </S.Notification>
                        </S.ImageAndNotification>

                        <S.ButtonLocalization>
                            <img src={IconLocalization} alt="Icone de localização" />
                            <p>{item.localization}</p>
                        </S.ButtonLocalization>
                    </S.CardNotification>
                ))}
            </S.Wrapper>
        </S.Container>
    );
};

export default NewCardNotification;
