import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import Input from "../../input/input_v2";

import api from "../../../services/api";
import { store } from "react-notifications-component";
import { Container, Row, Col, Hidden, ScreenClassRender } from "react-grid-system";
import { FormContainer, InfoCard, InfoCardWrapper, InfoCardPrevStepOne, Cards } from "./style";


const CompraStep2 = ({ ...props }) => {
    const { update, next, prev, prevToOne, categoria, nome, cnpj, logradouro, cidade, telefone, dataVencimento, responsavel, cargo, departamento, email, telefoneProps, numeroPedido } = props;
    const [dataVencimentoId, setDataVencimentoId] = useState(dataVencimento || '');
    const [responsavelId, setResponsavelId] = useState(responsavel || '');
    const [cargoId, setCargoId] = useState(cargo || '');
    const [departamentoId, setDepartamentoId] = useState(departamento || '');
    const [emailId, setEmailId] = useState(email || '');
    const [telefoneId, setTelefoneId] = useState(telefoneProps || '');
    const [numeroPedidoId, setNumeroPedidoId] = useState(numeroPedido || '');
    const [disabled, setDisabled] = useState(true);
    const [emailValidado, setEmailValidado] = useState(false);

    const [opDepartamento, setOpDepartamento] = useState([]);


    const nextStep = async e => {
        update(dataVencimentoId, responsavelId, cargoId, departamentoId, emailId, telefoneId, numeroPedidoId);
        next();
    }

    const  validateResponsavel = async valor => {
        setResponsavelId(valor);
    }

    const validateCargo = async valor => {
        setCargoId(valor);
    }

    const validateDepartamento = async valor => {
        console.log(valor.value);
        
        setDepartamentoId(valor.value);
    }

    let notificationShown = false;

    const validateEmail = (valor) => {
        setEmailId(valor);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(valor);

        if (isValid) {
            setEmailValidado(true);
            notificationShown = false; 
        } else {
            setEmailValidado(false);
        }
    };
    
    const validateEmailOnBlur = () => {
        if (!emailValidado && !notificationShown) {
            store.addNotification({
                title: "Erro!",
                message: "Email inserido é inválido.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true,
                },
            });
            notificationShown = true; 
        }
    };

    const validateTelefone = async valor => {
        setTelefoneId(valor);
    }

    const validateNumeroPedido = async valor => {
        setNumeroPedidoId(valor);
    }

    const formatDepartamentos = async departamentos => {
        departamentos = await departamentos.map(dep => {
            const format = {
                value: dep.id,
                label: `${dep.nome}`
            };

            return format;
        });

        setOpDepartamento(departamentos);
    }

    const getDepartamentos = async () => {

        try {
            const response = await api.get(`/info/departamentos`);
            let departamentos = response.data.data;
    
            await formatDepartamentos(departamentos);

            
        } catch (error) {
        }
    }

    useEffect(() => {
        if(emailId){
            validateEmail(emailId);
        }
        
        getDepartamentos();
        if( responsavelId && cargoId && departamentoId && emailId && telefoneId && emailValidado === true){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    },[])
    
    useEffect(() => {

        if( responsavelId && cargoId && departamentoId && emailId && telefoneId && emailValidado === true){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    },[ responsavelId, cargoId, departamentoId, emailId, telefoneId, emailValidado])

    return (
        <>
            <ScreenClassRender
                render={screenClass => (
                    <FormContainer>
                        <Container>
                            <Row>
                                <Col>
                                    <form>
                                        <Input
                                            name="responsavel"
                                            id="responsavelId"
                                            label="Responsável pelo contas a pagar"
                                            onChange={e => validateResponsavel(e)}
                                            value={responsavelId}
                                        />

                                        <Input
                                            name="cargo"
                                            label="Cargo"
                                            id="cargoId"
                                            onChange={e => validateCargo(e)}
                                            value={cargoId}
                                        />
                                        
                                        <Select
                                            name="departamento"
                                            id="departamentoId"
                                            label="Departamento"
                                            onChange={e => validateDepartamento(e)}
                                            options={opDepartamento}
                                            value={departamentoId.value || departamentoId}
                                            autoComplete="off"
                                            inputMode="none"
                                        />

                                        <Input
                                            name="email"
                                            id="emailid"
                                            label="Email p/ envio da NFe"
                                            type="email"
                                            onChange={e => validateEmail(e)}
                                            value={emailId}
                                            onBlur={() => validateEmailOnBlur()}
                                        />

                                        <Input
                                            name="telefone"
                                            id="telefoneid"
                                            label="Telefone"
                                            onChange={e => validateTelefone(e)}
                                            value={telefoneId}
                                            inputmode="numeric"
                                        />   

                                        <Input
                                            name="numero_pedido"
                                            id="numero_pedidoid"
                                            label="Número do pedido de compra (Opcional)"
                                            onChange={e => validateNumeroPedido(e)}
                                            value={numeroPedidoId}
                                        />

                                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '60px' }}>
                                            <Button 
                                                width='296px'
                                                height='50px'
                                                value='AVANÇAR'
                                                outline={false}
                                                disabled={disabled}
                                                onClick={() => nextStep()}
                                                loading={false}
                                            />
                                        </div>
                                    </form>
                                </Col>

                                <Col md={6} sm={0} >
                                    <Hidden sm xm styles={{ display: "flex" }}>
                                        <InfoCardWrapper show={screenClass}>
                                            <Cards>
                                                <InfoCardPrevStepOne onClick={prevToOne}>
                                                    <h1>Dados do plano</h1>
                                                </InfoCardPrevStepOne>

                                                <InfoCard id="cardee" show={screenClass} onClick={prev}>
                                                    <h1>Dados cadastrais</h1>
                                                    
                                                    <div className="item">
                                                        <small>Categoria</small>                                                
                                                        <p>{categoria}</p>
                                                    </div>

                                                    <div className="item">
                                                        <small>Nome Fantasia</small>
                                                        <p>{nome}</p>
                                                    </div>

                                                    <div className="item">
                                                        <small>CNPJ</small>
                                                        <p>{cnpj}</p>
                                                    </div>

                                                    <div className="item">
                                                        <small>Logradouro</small>
                                                        <p className="p-estado">{logradouro}</p>
                                                    </div>

                                                    <div className="item">
                                                        <small>Cidade</small>
                                                        <p className="p-estado">{cidade}</p>
                                                    </div>

                                                    <div className="item">
                                                        <small>Telefone</small>
                                                        <p className="p-estado">{telefone}</p>
                                                    </div>

                                                </InfoCard>
                                            </Cards>
                                        </InfoCardWrapper>
                                    </Hidden>
                                </Col>
                            </Row>
                        </Container>
                    </FormContainer>
                )}
            />
        </>
    );
};

export default CompraStep2;