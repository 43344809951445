import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import Input from "../../input/input_v2";
import estados from "../../../services/estados";
import api from "../../../services/api";
import viacep from "../../../services/viacep";
import { store } from "react-notifications-component";
import { Container, Row, Col, Hidden, ScreenClassRender } from "react-grid-system";
import { FormContainer, InfoCard, InfoCardWrapper } from "./style";
import validadorDeCpf from "../../../services/validadorCpf";
import buscaCep from "../../../services/buscaCep";
import validadorDeCnpj from "../../../services/validadorCnpj";


const CompraStep1 = ({ ...props }) => {
    const { update, next, prev, planoName, numUsers, valorTotal, typeBuy, categoria, razao, nome, cnpj, ie, cep, logradouro, numero, bairro, complemento, cidade, estado, telefone } = props;

    const [categoriaId, setCategoriaId] = useState(categoria || '');
    const [razaoSocialId, setRazaoSocialId] = useState(razao || '');
    const [nomeFantasiaId, setNomeFantasiaId] = useState(nome || '');
    const [cnpjId, setCnpjId] = useState(cnpj || '');
    const [inscricaoEstadualId, setInscricaoEstadualId] = useState(ie || '');
    const [cepId, setCepId] = useState(cep || '');
    const [logradouroId, setLogradouroId] = useState(logradouro || '');
    const [numeroId, setNumeroId] = useState(numero || '');
    const [bairroId, setBairroId] = useState(bairro || '');
    const [complementoId, setComplementoId] = useState(complemento || '');
    const [cidadeId, setCidadeId] = useState(cidade || '');
    const [estadoId, setEstadoId] = useState(estado || '');
    const [telefoneId, setTelefoneId] = useState(telefone || '');
    const [categoriaLabel, setCategoriaLabel] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [cnpjValidado, setCnpjValidado] = useState( cnpj ? true : false);
    const [tiposDeCompra] = useState([
        {value: 'PJ', label: 'Pessoa Jurídica'},
        {value: 'PF', label: 'Pessoa Física'},
    ]);
    const [tipoCompra, setTipoCompra] = useState(typeBuy || {value: 'PJ', label: 'Pessoa Jurídica'});
    const [isCpfValid, setIsCpfValid] = useState(false);

    const [loadingCategorias, setLoadingCategorias] = useState(false)

    const [erroCpf, setErroCpf] = useState(false);
    const [erroCnpj, setErroCnpj] = useState(false);
    const [opCategoria, setOpCategoria] = useState([]);
    const [loadingCep, setLoadingCep] = useState(false);

    const [load, setLoad] = useState(0);


    const [valueCpf, setValueCpf] = useState('');
    const [isButtonValid, setIsButtonValid] = useState(false);
    
    const nextStep = async e => {
        update(tipoCompra, categoriaId, razaoSocialId, nomeFantasiaId, cnpjId, inscricaoEstadualId, cepId, logradouroId, numeroId, bairroId, complementoId, cidadeId, estadoId, telefoneId, categoriaLabel);
        next();
    }

    const validateCategoria = async valor => {
        setCategoriaId(valor.value);
        setCategoriaLabel(valor.label);
    }

    const validateRazaoSocial = async valor => {
        setRazaoSocialId(valor);
    }

    const validateNomeFantasia = async valor => {
        setNomeFantasiaId(valor);
    }

    const validaCNPJ = async (valor) => {
        if (tipoCompra.value === 'PJ') {
            const valido = validadorDeCnpj(valor);
            console.log(valido);
    
            if (valido) {
                setCnpjValidado(true);
                setErroCnpj(false);
            } else {
                setCnpjValidado(false);
                setErroCnpj(true);
    
                store.addNotification({
                    title: "Erro!",
                    message: "CNPJ inserido é inválido.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    };



    const validateCnpj = async valor => {

        if (tipoCompra.value === 'PJ') {
            setCnpjId(valor);
            setErroCnpj(false);
        } else if (tipoCompra.value === 'PF') {
            let value = valor.replace(/\D/g, '');

            if (value.length <= 4){
                value = value.replace(/^(\d{3})(\d)/, '$1.$2');
            } else if (value.length <= 6) {
                value = value.replace(/^(\d{3})(\d)/, '$1.$2');
                value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
            } else if (value.length <= 9){
                value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3');
            } else if (value.length <= 11) {
                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
            } else if (value.length === 12) {
                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
            } else {
                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
            }
            setErroCpf(false);
            setCnpjId(value);
        }
    }

    const changeCpf = (valor) => {
        if (tipoCompra.value === 'PF') {
            let value = valor.replace(/\D/g, '');
    
            if (value.length <= 4){
                value = value.replace(/^(\d{3})(\d)/, '$1.$2');
            } else if (value.length <= 6) {
                value = value.replace(/^(\d{3})(\d)/, '$1.$2');
                value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
            } else if (value.length <= 9){
                value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3');
            } else if (value.length <= 11) {
                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
            } else if (value.length === 12) {
                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
            } else {
                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
            }

            const valueCpfKeep = value
    
            setValueCpf(valueCpfKeep); 
            setCnpjId(value);

            if (value.length === 14) { 
                const CpfValido = validadorDeCpf(value);
    
                if (!CpfValido) {
                    store.addNotification({
                        title: "Erro!",
                        message: "CPF inserido é inválido.",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                    setErroCpf(true);
                    setIsCpfValid(false); 
                } else {
                    setErroCpf(false);
                    setIsCpfValid(true); 
                }
            }
        }
    };
    
    useEffect(() => {
        if (valueCpf) {
            setIsButtonValid(true); 
        } else {
            setIsButtonValid(false);
        }
    }, [valueCpf]);

    const validateInscricaoEstadual = async valor => {
        if (tipoCompra.value === 'PJ') {
            setInscricaoEstadualId(valor);
        } else if (tipoCompra.value === 'PF') {
            
            let value = valor.replace(/\W/g, '');
            value = value.toUpperCase();
            setInscricaoEstadualId(value);
        }
    }

    const validateCep = async valor => {
        setCepId(valor);
    }

    const validateLogradouro = async valor => {
        setLogradouroId(valor);
    }

    const validateNumero = async valor => {
        setNumeroId(valor);
    }

    const validateBairro = async valor => {
       setBairroId(valor); 
    }

    const validateComplemento = async valor => {
        setComplementoId(valor);
    }

    const validateCidade = async valor => {
        setCidadeId(valor);  
    }

    const validateEstado = async valor => {
        setEstadoId(valor.value);
    }

    const validateTelefone = async valor => {
        setTelefoneId(valor);
    }

    const validateTipoCompra = (valor) => {
        setTipoCompra(valor);
        setCnpjId('');
    }

    const formatCategorias = async (categorias) => {
        categorias = await categorias.map(c => {
            const format = {
                value: c.id,
                label: `${c.nome}`
            };

            return format;
        });

        setOpCategoria(categorias);
    }

    const getCategorias = async () => {
        setLoadingCategorias(true);
        try {
            const response = await api.get(`/info/categorias`);
            let categorias = response.data.data;

            await formatCategorias(categorias);
            setLoadingCategorias(false);

        } catch (error) {
            setLoadingCategorias(false);
        }
    }


    const fetchCep = async () => {
        try {
            setLoadingCep(true);
            const cep = await buscaCep(cepId);

            const { logradouro, ibge, bairro, localidade, uf } = cep;
    
            setLogradouroId(logradouro);
            setBairroId(bairro);
            setCidadeId(localidade);
            setEstadoId(uf);

            setLoadingCep(false);         

        } catch (err) {

            setLoadingCep(false);         

            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }


    useEffect(() => {
        
        getCategorias();

    },[])

    useEffect(() => {

    },[cnpjValidado, isCpfValid, cnpjId])


    useEffect(() => {

        if (tipoCompra.value === 'PJ') {
            if(categoriaId && razaoSocialId && nomeFantasiaId && cnpjId && inscricaoEstadualId && cepId && logradouroId && numeroId && bairroId && cidadeId && estadoId && telefoneId && cnpjValidado === true){
                setDisabled(false);
            }else{
                setDisabled(true);
            }
        } else if (tipoCompra.value === 'PF') {
            if (
                categoriaId &&
                razaoSocialId &&
                nomeFantasiaId &&
                cnpjId &&
                inscricaoEstadualId &&
                cepId &&
                logradouroId &&
                numeroId &&
                bairroId &&
                cidadeId &&
                estadoId &&
                telefoneId &&
                isCpfValid === true &&
                isButtonValid === true
            ) {
                setDisabled(false);
            }else{
                setDisabled(true);
            }
        }

    },[tipoCompra, categoriaId, razaoSocialId, nomeFantasiaId, cnpjId, inscricaoEstadualId, cepId, logradouroId, numeroId, bairroId, cidadeId, estadoId, telefoneId, cnpjValidado, isCpfValid,  isButtonValid])


    useEffect(() => {

        console.log("TIPO => ", tipoCompra)

        if (load > 2) {
            console.log("LIMPAR");

            setCnpjId('');
            setCnpjValidado(false);
            setErroCnpj(false);
    
            setIsCpfValid(false);
            setErroCpf(false);
        } else {
            setLoad(load+1);
        }

    }, [tipoCompra])
    return (
        <>
        <ScreenClassRender
            render={screenClass => (
                <FormContainer>
                    <Container>
                        <Row>
                            <Col>
                                <form>
                                    <Select
                                        name="tipo_compra"
                                        id="id_tipo_compra_id"
                                        label="Comprar como"
                                        onChange={e => validateTipoCompra(e)}
                                        options={tiposDeCompra}
                                        value={tipoCompra.value || tipoCompra}
                                    />
                                    
                                    <Select
                                        name="categoria"
                                        id="id_categoria_id"
                                        label="Categoria"
                                        onChange={e => validateCategoria(e)}
                                        options={opCategoria}
                                        value={categoriaId.value || categoriaId}
                                        loading={loadingCategorias}
                                        disabled={loadingCategorias}
                                    />

                                    {tipoCompra.value === 'PJ' ? (
                                        <Input
                                            name="razao_social"
                                            id="id_razao_social_pj"
                                            label='Razão social'
                                            onChange={e => validateRazaoSocial(e)}
                                            value={razaoSocialId}
                                        />

                                    ) : (null)}

                                    {tipoCompra.value === 'PF' ? (
                                        <Input
                                            name="razao_social"
                                            id="id_razao_social_pf"
                                            label="Nome completo"
                                            onChange={e => validateRazaoSocial(e)}
                                            value={razaoSocialId}
                                        />
                                    ) : (null)}

                                    {tipoCompra.value === 'PJ' ? (
                                        <Input
                                            name="nome_fantasia"
                                            id="id_nome_fantasia_pj"
                                            label='Nome fantasia'
                                            value={nomeFantasiaId}  
                                            onChange={e => validateNomeFantasia(e)}
                                        />
                                    ) : (null)}
                                    
                                    {tipoCompra.value === 'PF' ? (
                                        <Input
                                            name="nome_fantasia"
                                            id="id_nome_fantasia_pf"
                                            label='Primeiro nome'
                                            value={nomeFantasiaId}  
                                            onChange={e => validateNomeFantasia(e)}
                                        />
                                    ) : (null)}
                                    
                                    {tipoCompra.value === 'PJ' ? (
                                        <Input
                                            name="cnpj"
                                            label='CNPJ'
                                            id="id_cnpj"
                                            value={cnpjId}
                                            onChange={e => validateCnpj(e)}
                                            onBlur={e => validaCNPJ(e.target.value)}
                                            inputmode="numeric"
                                            erro={cnpjId === '' ? false : erroCnpj}
                                        />
                                    ) : (null)}
                                    
                                    {tipoCompra.value === 'PF' ? (
                                        <Input
                                            name="cpf"
                                            label='CPF'
                                            id="id_cpf"
                                            erro={cnpjId === '' ? false : erroCpf}
                                            value={cnpjId}
                                            onChange={e => changeCpf(e)}
                                            inputmode="numeric"
                                            maxLength={14}
                                        />
                                    ) : (null)}
                                    
                                    {tipoCompra.value === 'PJ' ? (
                                        <Input
                                            name="inscricao_estadual"
                                            id="id_inscricao_estadual_pj"
                                            label='Inscrição estadual'
                                            value={inscricaoEstadualId}
                                            onChange={e => validateInscricaoEstadual(e)}
                                            inputmode="numeric"
                                        />
                                    ) : (null)}
                                    
                                    {tipoCompra.value === 'PF' ? (
                                        <Input
                                            name="inscricao_estadual"
                                            id="id_inscricao_estadual_pf"
                                            label='RG'
                                            value={inscricaoEstadualId}
                                            onChange={e => validateInscricaoEstadual(e)}
                                            inputmode="numeric"
                                        />
                                    ) : (null)}



                                    <Input
                                        name="cep"
                                        label="CEP"
                                        id="id_cepLocal"
                                        value={cepId}
                                        onChange={e => validateCep(e)}
                                        onBlur={() => fetchCep()}
                                        inputmode="numeric"
                                        loading={loadingCep}
                                        disabled={loadingCep}
                                    />


                                    <Input
                                        name="logradouro"
                                        id="id_logradouro"
                                        label="Logradouro"
                                        value={logradouroId}
                                        onChange={e => validateLogradouro(e)}
                                        loading={loadingCep}
                                        disabled={loadingCep}
                                    />  

                                    <Input
                                        name="numero"
                                        id="id_numero"
                                        label="Número"
                                        onChange={e => validateNumero(e)}
                                        value={numeroId}
                                        inputmode="numeric"
                                        
                                    />

                                    <Input
                                        name="bairro"
                                        id="id_bairro"
                                        label="Bairro"
                                        value={bairroId}
                                        onChange={e => validateBairro(e)}
                                        loading={loadingCep}
                                        disabled={loadingCep}
                                    />

                                    <Input
                                        name="complemento"
                                        id="id_complemento"
                                        label="Complemento (Opcional)"
                                        onChange={e => validateComplemento(e)}
                                        value={complementoId}
                                    />  

                                    <Input
                                        name="cidade"
                                        id="id_cidade"
                                        label="Cidade"
                                        value={cidadeId}
                                        onChange={e => validateCidade(e)}
                                        loading={loadingCep}
                                        disabled={loadingCep}
                                    />

                                    <Select
                                        name="estado"
                                        id="id_estado"
                                        label="Estado"
                                        value={estadoId.value || estadoId}
                                        options={estados}   
                                        estado={true} 
                                        onChange={e => validateEstado(e)}
                                        loading={loadingCep}
                                        disabled={loadingCep}
                                    />

                                    <Input
                                        name="telefone"
                                        id="id_tel"
                                        label="Telefone"
                                        onChange={e => validateTelefone(e)}
                                        value={telefoneId}
                                        inputmode="numeric"
                                    />

                                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '60px' }}>
                                        <Button 
                                            width='296px'
                                            height='50px'
                                            value='AVANÇAR'
                                            outline={false}
                                            disabled={disabled}
                                            onClick={() => nextStep()}
                                            loading={false}
                                        />
                                    </div>
                                </form>
                            </Col>

                            <Col md={6} sm={0} >
                                <Hidden sm xm styles={{ display: "flex" }}>
                                    <InfoCardWrapper show={screenClass}>
                                        <InfoCard id="cardee" show={screenClass} onClick={prev}>
                                            <h1>Dados do plano</h1>
                                            
                                            <div className="item">
                                                <small>Plano</small>                                                
                                                <p>{planoName}</p>
                                            </div>

                                            <div className="item">
                                                <small>Número usuários</small>
                                                <p className="p-estado">{numUsers}</p>
                                            </div>

                                            <div className="item">
                                                <small>Valor total</small>
                                                <p className="p-estado">R${valorTotal}</p>
                                            </div>

                                        </InfoCard>
                                    </InfoCardWrapper>
                                </Hidden>
                            </Col>
                        </Row>
                    </Container>
                </FormContainer>
            )}
        />

        </>
    );
};

export default CompraStep1;