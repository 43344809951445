import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import {
    Container,
    SessionRow,
    Card,
    FormContainer,
    Line,
    ContainerLogo
} from './style';
import Input from '../../components/input/input_v2';
import Button from '../../components/button/button_v2';
import logoBranco from '../../assets/simbolo-branco-1.png';
import Logo from "../../assets/logo_hd.svg";
import CardIcon from "../../assets/icons/payment-card-icon.svg";

import ModalTermos from '../../components/modal/modalTermos';
import ModalPoliticas from '../../components/modal/modalPoliticas';

import { store } from "react-notifications-component";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import IconLinkedin from "../../assets/icons/linkedin";
import LinkedIn, { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { base64UrlEncode } from '../../services/globalFunctions';

export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';


const CadastroStep1 = ({ ...props }) => {

    const [nome, setNome] = useState('');
    const [erroNome, setErroNome] = useState(false);
    const [erroNomeMessage, setErroNomeMessage] = useState('');

    const [email, setEmail] = useState('');
    const [erroEmail, setErroEmail] = useState(false);
    const [erroEmailMessage, setErroEmailMessage] = useState('');

    const [showPolitica, setShowPolitica] = useState(false);
    const [showTermos, setShowTermos] = useState(false);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [emailGoogle, setEmailGoogle] = useState('');
    const [emailLinkedin, setEmailLinkedin] = useState('');
    const [nomeLinkedin, setNomeLinkedin] = useState('');
    const [idLinkedin, setIdLinkedin] = useState('');

    const [loadingEmail, setLoadingEmail] = useState(false);
    const [emailIsOk, setEmailIsOk] = useState(false);

    const [queryParams, setQueryParams] = useState('');


    const changeNome = (value) => {
        setNome(value);
        setErroNome(false);
    }

    const changeEmail = (value) => {
        setEmail(value);
        setErroEmail(false);
    }


    const clickPolitica = () => {
        setShowPolitica(true);
    }

    const clickTermos = () => {
        setShowTermos(true);
    }

    const handlePost = async () => {
        setLoading(true);

        const payload = {
            nome,
            email,
        };

        props.updateData(payload);
        setLoading(false);
    }

    const handlePostSocial = async () => {
        setLoading(true);

        const a = localStorage.getItem('@agroreceita_google_user_profile');
        let userProfile = JSON.parse(a);

        const payload = {
            name: userProfile.name,
            email: userProfile.email,
            id: userProfile.id
        };

        try {
            setLoadingEmail(true);
            const response = await api.post(`/cadastro/validaEmail`, { email: payload.email });

            if (response) {
                setEmailIsOk(true);
                props.updateData(payload);
            }

            setLoadingEmail(false);

        } catch (error) {
            setLoadingEmail(false);

            let urlEncode = base64UrlEncode(payload.email + ';'+ null + ';'+ true);
            window.location = `/login?cadastro=true&q=${urlEncode}`;
        }

        setLoading(false);
    }

    const handlePostLinkedin = async () => {
        setLoading(true);

        // const nome = localStorage.getItem('@agroreceita_linkedin_nome_user');
        // const idinkedin = localStorage.getItem('@agroreceita_linkedin_id');

        const payload = {
            name: nomeLinkedin,
            email: emailLinkedin,
            id: idLinkedin
        };

        try {
            setLoadingEmail(true);
            const response = await api.post(`/cadastro/validaEmail`, { email: payload.email });

            if (response) {
                setEmailIsOk(true);
                props.updateData(payload);
            }

            setLoadingEmail(false);

        } catch (error) {
            setLoadingEmail(false);

            if (error.response.status === 417) {
                let urlEncode = base64UrlEncode(emailLinkedin + ';'+ null + ';'+ true);
                window.location = `/login?cadastro=true&q=${urlEncode}`;
            } else {
                localStorage.removeItem(GOOGLE_EMAIL);
                localStorage.removeItem(GOOGLE_USER);
                localStorage.removeItem(GOOGLE_USER_PROFILE);

                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }

        setLoading(false);
    }

    const { linkedInLogin } = useLinkedIn({
        clientId: '77u1m9fh81cpbq',
        redirectUri: process.env.REACT_APP_API_HOST_LINKEDIN, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: ['r_liteprofile', 'r_emailaddress'],
        onSuccess: (code) => {
            console.log(code);
            alert('sucesso');
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const goLogin = () => {
        window.location = '/login?cadastro=true';
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    
    function handleCredentialResponse(credential) {
    
        if (credential) {
            var profile = parseJwt(credential);  
            var google_user = { profile };
        
            var profileUserGoogle = {
                id: profile.sub,
                name: profile.name,
                email: profile.email
            }
        
            console.log('profile', profileUserGoogle);
        
            var exit = localStorage.getItem('@agroreceita_exit');
        
            localStorage.setItem("@agroreceita_google_email", profile.email);
            localStorage.setItem("@agroreceita_google_user", JSON.stringify(google_user));
            localStorage.setItem("@agroreceita_google_user_profile", JSON.stringify(profileUserGoogle));

            setEmailGoogle(profile.email);
        }
    }

    const checkSocialLogin = () => {
        setInterval(() => {

            let queryCredential = decodeURIComponent(new URLSearchParams(window.location.search).get('credential'));

            let queryEmail = decodeURIComponent(new URLSearchParams(window.location.search).get('username'));
            let queryNome = decodeURIComponent(new URLSearchParams(window.location.search).get('nome'));
            let queryId = decodeURIComponent(new URLSearchParams(window.location.search).get('id'));
                        
            let queryEmailLS = localStorage.getItem('@agroreceita_linkedin_email');
            let queryNomeLS = localStorage.getItem('@agroreceita_linkedin_nome_user');
            let queryIdLS = localStorage.getItem('@agroreceita_linkedin_id');

            if (queryCredential && queryCredential !== 'null') {
                handleCredentialResponse(queryCredential);
            } else {
                if (email && !emailGoogle) {
                    setEmailGoogle(email);
                }else if(queryEmail && queryEmail !== 'null'){
                    setIdLinkedin(queryId);
                    setNomeLinkedin(queryNome);
                    setEmailLinkedin(queryEmail);
                } else if (queryEmailLS && queryEmailLS !== 'null' && queryEmailLS !== null && queryEmailLS !== undefined) {
                    setIdLinkedin(queryIdLS);
                    setNomeLinkedin(queryNomeLS);
                    setEmailLinkedin(queryEmailLS);
                }
            }



        }, 300);
    }

    const validaEmail = async (e) => {

        try {
            if (e) {
                setLoadingEmail(true);
                const response = await api.post(`/cadastro/validaEmail`, { email: e });

                if (response) {
                    setEmailIsOk(true);
                }

                setLoadingEmail(false);
            } else {
                setEmailIsOk(false);
            }

        } catch (error) {
            setLoadingEmail(false);
            setEmailIsOk(false);
            setErroEmail(true);
            setErroEmailMessage(error.response.data.errors);

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const getQueryParams = () => {
        const utm_id = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_id'));
        const utm_source = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_source'));
        const utm_medium = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_medium'));
        const utm_campaign = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_campaign'));
        const button = decodeURIComponent(new URLSearchParams(window.location.search).get('button'));

        let queryString = '';

        if (utm_id && utm_id !== 'null') {
            queryString = `${queryString}&utm_id=${utm_id}`;
        } else {
            queryString = `${queryString}&utm_id=0`;
        }

        if (utm_source && utm_source !== 'null') {
            queryString = `${queryString}&utm_source=${utm_source}`;
        } else {
            queryString = `${queryString}&utm_source=orgânico`;
        }

        if (utm_medium && utm_medium !== 'null') {
            queryString = `${queryString}&utm_medium=${utm_medium}`;
        } else {
            queryString = `${queryString}&utm_medium=orgânico`;
        }
        
        if (utm_campaign && utm_campaign !== 'null') {
            queryString = `${queryString}&utm_campaign=${utm_campaign}`;
        } else {
            queryString = `${queryString}&utm_campaign=cadastro teste grátis`;
        }
        
        if (button && button !== 'null') {
            queryString = `${queryString}&button=${button}`;
        } else {
            queryString = `${queryString}&button=0`;
        }

        setQueryParams(queryString);

    }

    useEffect(() => {
        getQueryParams();
        checkSocialLogin();

        setTimeout(() => {
            let aTags = document.getElementsByTagName("span");
            let searchText = "Login";

            for (let i = 0; i < aTags.length; i++) {
                if (aTags[i].textContent == searchText) {
                    aTags[i].innerText = 'Continuar com Google';
                    break;
                }
            }
            setInterval(() => {
                let aTags = document.getElementsByTagName("span");
                let searchText = "Login";

                for (let i = 0; i < aTags.length; i++) {
                    if (aTags[i].textContent == searchText) {
                        aTags[i].innerText = 'Continuar com Google';
                        break;
                    }
                }
            }, 500);
        }, 300);
    }, [])

    useEffect(() => {
        console.log(emailGoogle);
        if (emailGoogle && emailGoogle !== "null") {
            handlePostSocial();
        }
    }, [emailGoogle])

    useEffect(() => {
        if (emailLinkedin && nomeLinkedin && idLinkedin) {
            handlePostLinkedin();
        }
    }, [emailLinkedin, nomeLinkedin, idLinkedin])

    useEffect(() => {
        if (nome && email) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [nome, email])

    return (
        <Container>
            <SessionRow>
                <Card backgroundColor='#fff' width='100vw'>
                    <FormContainer>

                        <div className="btnGoogle">
                            <div id="g_id_onload"
                                data-client_id={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
                                data-context="signin"
                                data-ux_mode="redirect"
                                data-login_uri={`${process.env.REACT_APP_API_HOST}/loginsocial`}
                                data-auto_prompt="false">
                            </div>

                            <div class="g_id_signin"
                                data-type="standard"
                                data-shape="rectangular"
                                data-theme="outline"
                                data-text="signin_with"
                                data-size="large"
                                data-locale="pt-BR"
                                data-logo_alignment="left">
                            </div>
                        </div>

                        {/* <div className="btnLinkedin"
                            onClick={linkedInLogin}
                            alt="Iniciar sessão com o Linkedin"
                        >
                            <IconLinkedin width="25px" heigh="25px" /><span className="iniciar">Iniciar sessão com o Linkedin</span>
                        </div> */}

                        <Line>
                            <hr />
                            <span>OU</span>
                        </Line>

                        <Input
                            id="nome"
                            value={nome}

                            label="Nome"
                            onChange={(e) => changeNome(e)}
                            erro={erroNome}
                            messageErro={erroNomeMessage}
                            marginBottom='0px'
                        />
                        <Input
                            id="email"
                            value={email}

                            label="E-mail"
                            onChange={(e) => changeEmail(e)}
                            erro={erroEmail}
                            messageErro={erroEmailMessage}
                            marginBottom='0px'
                            onBlur={(e) => validaEmail(e.target.value)}
                            loading={loadingEmail}
                            disabled={loadingEmail}
                            isValid={emailIsOk}
                        />

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '15px' }}>
                            <label className='link' onClick={() => goLogin()}>Já tenho cadastro</label>
                            <Button
                                width='139px'
                                height='48px'
                                value='Continuar'
                                outline={false}
                                disabled={disabled || !emailIsOk}
                                onClick={() => handlePost()}
                                loading={loading}
                            />
                        </div>

                        <div className='terms'>
                            <p>Ao continuar você concorda com a <label onClick={() => clickPolitica()}>Politica de Privacidade</label> e <label onClick={() => clickTermos()}>Termos de Uso</label></p>
                        </div>

                    </FormContainer>
                </Card>
            </SessionRow>


            <ModalTermos
                show={showTermos}
                btnOk={true}
                onHide={() => setShowTermos(false)}
            ></ModalTermos>

            <ModalPoliticas
                show={showPolitica}
                btnOk={true}
                onHide={() => setShowPolitica(false)}
            ></ModalPoliticas>
        </Container>
    )
}

export default CadastroStep1;