import React, { useState, useEffect } from 'react';

import CadastroStep1 from './step1_site';
import CadastroStep2 from './step2_site';
import { clearSession } from '../../../services/auth';

const CadastroSite = ({...props}) => {

    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState('');
    const [params, setParams] = useState([]);

    const update = (data) => {
        setStep1Data(data);
        setStep(2);
    }

    const getParams = () => {

        const utm_id = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_id'));
        const utm_source = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_source'));
        const utm_medium = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_medium'));
        const utm_campaign = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_campaign'));
        const button = decodeURIComponent(new URLSearchParams(window.location.search).get('button'));
        const device_id = decodeURIComponent(new URLSearchParams(window.location.search).get('device_id'));

        const payload = {
            utm_id: utm_id && utm_id !== 'null' ? utm_id : 0,
            utm_source: utm_source && utm_source !== 'null' ? utm_source : 'orgânico',
            utm_medium: utm_medium && utm_medium !== 'null' ? utm_medium : 'orgânico',
            utm_campaign: utm_campaign && utm_campaign !== 'null' ? utm_campaign : 'orgânico',
            button: button && button !== 'null' ? button : '0',
            device_id: device_id && device_id !== 'null' ? device_id : '',
        }

        return payload;
    }

    useEffect(() => {

        if(localStorage.getItem("@agroreceita_google_user_profile")){
            const dadosCadastro = JSON.parse(localStorage.getItem("@agroreceita_google_user_profile"))
            console.log(localStorage.getItem('@agroreceita_linkedin_id'), localStorage.getItem('@agroreceita_linkedin_nome_user'), localStorage.getItem('@agroreceita_linkedin_email'))
            if (dadosCadastro.id !== undefined && dadosCadastro.name !== undefined && dadosCadastro.email !== undefined) {
                update({
                    id: dadosCadastro.id,
                    name: dadosCadastro.name,
                    email: dadosCadastro.email
                });
                
                setStep(2);
            }else{
                clearSession()
            }
        }else if(localStorage.getItem('@agroreceita_linkedin_id') && localStorage.getItem('@agroreceita_linkedin_nome_user') && localStorage.getItem('@agroreceita_linkedin_email')){
            update({
                id: localStorage.getItem('@agroreceita_linkedin_id'),
                name: localStorage.getItem('@agroreceita_linkedin_nome_user'),
                email: localStorage.getItem('@agroreceita_linkedin_email')
            });
            
            setStep(2);
            
        }else{
            clearSession()
        }


        // let queryCredential = decodeURIComponent(new URLSearchParams(window.location.search).get('credential'));

        // if (queryCredential && queryCredential !== 'null') {

        // } else {
            // let search = props.location.search;
            // search = search.split('?');
            // search = search[1];
    
            // const auth = localStorage.getItem('@agroreceita_linkedin_auth');
            // console.log("AUTH => ", auth);
    
            let prms = getParams();
            setParams(prms);
    
            // if (search) {
                // window.location = 'https://sistema.agroreceita.com.br/cadastro/' + search;
                // window.location = '/cadastro?' + search;
            // }
        // }

    }, [])

    console.log("History:",props.history)

    return (
        <>
            {step === 1 ? (
                <CadastroStep1 
                    updateData={(data) => update(data)}
                    next={() => setStep(2)}
                />
            ) : (
                <CadastroStep2 
                    step1Data={step1Data}
                    next={() => setStep(3)}
                    // history={props.history}
                    params={params}
                />
            )}
        </>
    )
}

export default CadastroSite;