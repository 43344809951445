import React from 'react';
import { withRouter } from "react-router-dom";

import * as S from './styles';
import NewHeader from '../../components/nova-home/header';
import NewCardNotification from '../../components/nova-home/card-notification';

export const compendioG = "@compendioG";

const NovaHome = ({ ...props }) => {
    return (
        <S.Container>
            <S.Wrapper>
                <NewHeader />

                <NewCardNotification />
            </S.Wrapper>
        </S.Container>
    );
};

export default withRouter(NovaHome);
