import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "../src/pages/login";
import LoginLinkedinPage from "../src/pages/login/linkedin";
import LoginPageoff from "../src/pages/login/indexoff";
import Home from "../src/pages/home";
import Agronomos from "../src/pages/agronomos";
import AddAgronomos from "../src/pages/agronomos/add";
import EditAgronomos from "../src/pages/agronomos/edit";
import EditCrea from "./pages/agronomos/editCrea";
import AddCrea from "./components/creas/creas/addCrea";
import Arts from "../src/pages/arts";
import AddArts from "../src/pages/arts/add";
import EditArts from "../src/pages/arts/edit";
import EditContracts from "../src/pages/arts/specific";
import newContract from "../src/pages/arts/contracts";
import ContractLet from "../src/pages/arts/editContract";
import Devolucao from "./pages/devolucoes";
import AddDevolucao from "./pages/devolucoes/add";
import EditDevolucao from "../src/pages/devolucoes/edit";
import Filiais from "../src/pages/filiais";
import AddFiliais from "./pages/filiais/add";
import EditFiliais from "../src/pages/filiais/edit";
import Produtores from "../src/pages/produtores";
import AddProdutores from "../src/pages/produtores/add";
import EditProdutores from "../src/pages/produtores/edit";
import EditLocal from "../src/pages/produtores/editLocal";
import Fazendas from "../src/pages/produtores/indexlocal";
import AddLocal from "../src/pages/produtores/addlocal";
import AddReceitas from "../src/pages/receitas/add";
import AddReceitasLote from "../src/pages/receitas/addLote";
import Compendio from "../src/pages/compendio/index";
import Historico from "../src/pages/historico/index";
import AddUsers from "../src/pages/usuarios/add";
import EditUsers from "../src/pages/usuarios/edit";
import Users from "../src/pages/usuarios/index";
import NotFound from "../src/pages/404/index";
import Solicitar from "../src/pages/solicitar/index";
import Empresa from "../src/pages/filiais/indexEmpresa";
import Relatorios from '../src/pages/historico/relatorios';
import Notificacao from "../src/pages/notificacao/index";
import Planos from "../src/pages/planos/index";
import TourSteps from "./pages/tour/index";
import DocSeguranca from "../src/pages/documentosSeg/index";
import SimulaPlanos from "../src/pages/planos/simulaPlanos.js";
import Simulacao from "../src/pages/planos/simulacao.js";
import CompendioGratis from "../src/pages/compendio/compendio-gratis";
import Compra from "../src/pages/compra";
import CompraSuccess from "../src/pages/compra/success";
import Cadastro from '../src/pages/cadastro';
import CadastroSite from '../src/pages/cadastro/site';
import CadastroFree from '../src/pages/cadastro/free';
import CadastroSucesso from '../src/pages/cadastro/success';
import Senha from '../src/pages/cadastro/password';
import HelpNewReceita from '../src/pages/extras/new_prescription';
import ComprarReceitas from '../src/pages/compra/receita';
import ComprarLimite from '../src/pages/compra/limite';
import Faturamento from '../src/pages/faturamento';
import Integracao from '../src/pages/integracao';
import GuiaAplicacao from '../src/pages/guiaAplicacao';
import Blog from '../src/pages/blog';
import Agenda from '../src/pages/agenda';
import Crm from '../src/pages/crm';
import ValidacaoReceita from '../src/pages/receitas/validacao';
import PrevisaoTempo from "./pages/previsaoTempo";
import PrevisaoTempoFarm from './pages/previsaoTempo/farm.js';
import EditarPlano from './pages/planos/edit.js';
import ReceituarioAgronomico2024 from "./pages/receituario-agronomico-2024/index.js";
// import BlackNovember2024 from "./pages/black-november-2024";
import NovaHome from "./pages/nova-home";

import { isAuthenticated } from "./services/auth";
import { upConfigRouteToNavbar } from "./services/conNavbar";

const PrivateRoute = ({ component: Component, isMobile, ...rest }) => {
  const [path, setPath] = useState('');
  const [location, setLocation] = useState('');
  const [mounted, setMounted] = useState(false);
  const [redirect, setRedirect] = useState(false);


  // const setConfig = (rest) => {
  //   setPath(rest.path)
  //   upConfigRouteToNavbar(rest)
  // }
  
  // useEffect(() => {
  //   if(redirect === true){
  //     const currentPath = window.location.pathname;
  //     console.log('path', currentPath)
  //     if (currentPath !== "/" && currentPath !== "/login") {
  //       localStorage.setItem("lastAttemptedPath", window.location.href);
  //     }
  //   }
  // }, []);


  useEffect(() => {
    if (mounted === false) {
      setPath(rest.path)
      upConfigRouteToNavbar(rest)
      setMounted(true);
    }
  }, [])
  return (
    <>
      {mounted && path ? (
        <Route
          {...rest}

          render={props =>
            isAuthenticated() ? (
              <>
                {path && (
                  <>
                    {path !== rest.path ? (
                      <>
                        <Component {...props} isMobile={isMobile} config={rest} />
                      </>
                    ) : (
                      <Component {...props} isMobile={isMobile} config={rest} />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {localStorage.setItem("lastAttemptedPath", window.location.href)}
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
                
              </>
            )
          }
        />
      ) : (null)}
    </>
  );
}

{ console.log("RENDER ROUTER") }

const Routes = ({ ...props }) => (
  <Switch>
    <PrivateRoute exact path="/" component={Home} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/agronomos" component={Agronomos} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/agronomos/add" component={AddAgronomos} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/agronomos/:id" component={EditAgronomos} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/arts" component={Arts} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/arts/add" component={AddArts} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/arts/:id" component={EditArts} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/creas/:id" component={EditCrea} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/creas/add/:id" component={AddCrea} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/compendio" component={Compendio} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/contracts/:id" component={EditContracts} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/compra/sucesso/" component={CompraSuccess} showMenu={false} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/compra" component={Compra} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/devolucoes" component={Devolucao} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/devolucoes/add" component={AddDevolucao} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/devolucoes/:id" component={EditDevolucao} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/editcontract/:id" component={ContractLet} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/documentos_seguranca" component={DocSeguranca} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/empresa" component={Empresa} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/filiais" component={Filiais} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/filiais/add" component={AddFiliais} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/filiais/:id" component={EditFiliais} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/fazendas/:idfazenda/:idprodutor" component={EditLocal} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/fazendas" component={Fazendas} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/historico" component={Historico} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/localdeaplicacao/add/:id" component={AddLocal} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/newcontract/:id" component={newContract} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/notificacao" component={Notificacao} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/produtores" component={Produtores} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/produtores/add" component={AddProdutores} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/produtores/:id" component={EditProdutores} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/planos" component={Planos} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/plano/edit" component={EditarPlano} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/receitas/add"
      component={AddReceitas}
      showMenu={true}
      prop={props}
      isMobile={props.isMobile}
    />

    <PrivateRoute exact path="/receitas/addlote"
      component={AddReceitasLote}
      showMenu={true}
      prop={props}
      isMobile={props.isMobile}
    />

    <PrivateRoute exact path="/receitas/add/?:culturaId/:pragaId/:produtoId/modoId" component={AddReceitas} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/relatorios" component={Relatorios} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/solicitar" component={Solicitar} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/usuarios/add" component={AddUsers} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/usuarios" component={Users} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/usuarios/:id" component={EditUsers} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/simulacao/:id" component={SimulaPlanos} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/extras/nova_receita" component={HelpNewReceita} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/comprar_receitas" component={ComprarReceitas} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/comprar_limite" component={ComprarLimite} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/faturamento" component={Faturamento} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/integracao" component={Integracao} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/guia_aplicacao" component={GuiaAplicacao} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/previsao_tempo" component={PrevisaoTempo} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/previsao_tempo/:id" component={PrevisaoTempoFarm} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/blog" component={Blog} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/agenda" component={Agenda} showMenu={true} prop={props} isMobile={props.isMobile} />
    <PrivateRoute exact path="/crm" component={Crm} showMenu={true} prop={props} isMobile={props.isMobile} />

    <Route exact path="/nova-home" component={NovaHome} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/compendio-gratis" component={CompendioGratis} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/cadastro" component={Cadastro} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/cadastro/site" component={CadastroSite} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/cadastro/free" component={CadastroFree} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/cadastro/sucesso" component={CadastroSucesso} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/apresentacao" component={TourSteps} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/senha/:ticket" search="/:ticket" component={Senha} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/validador" component={ValidacaoReceita} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route exact path="/linkedin/login" component={LoginLinkedinPage} showMenu={true} prop={props} isMobile={props.isMobile} />
    <Route exact path="/receituario-agronomico-2024" component={ReceituarioAgronomico2024} showMenu={false} prop={props} isMobile={props.isMobile} />
    {/* <Route exact path="/black-november-2024" component={BlackNovember2024} showMenu={false} prop={props} isMobile={props.isMobile} /> */}

    <Route path="/login-compra/:id" search="/:id" component={LoginPageoff} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route path="/login" component={Login} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route path="/simulacaoplano/:id" search="/:id" component={Simulacao} showMenu={false} prop={props} isMobile={props.isMobile} />
    <Route path="*" component={NotFound} showMenu={false} prop={props} isMobile={props.isMobile} />

  </Switch>
);


export default Routes;
