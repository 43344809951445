import React, { useEffect, useState } from "react";
import Routes from "./routes";
import GlobalStyles, { Layout, Main } from "./styles/global";
import { ScreenClassProvider } from "react-grid-system";
import ReactNotification from "react-notifications-component";
import Navbar from "../src/components/navbar";
import { BrowserRouter } from "react-router-dom";
import BlackNovember from "../src/components/countdown-black-system/index";
import { logged } from "./services/auth";
export const USER = "@userinfo";

const App = () => {
  const [isFull, setIsFull] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [routeConfig, setRouteConfig] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isBlackNovemberVisible, setIsBlackNovemberVisible] = useState(false);
  const [blackNovember, setBlackNovember] = useState(false)
  const [statusLogged, setStatusLogged] = useState(localStorage.getItem("@logged"))

  const currentDate = new Date();
  const targetDate = new Date('2024-11-29T23:59:59');

  const handleBlackNovemberVisibility = (isVisible) => {
    setIsBlackNovemberVisible(isVisible);

    if(currentDate > targetDate) {
      setIsBlackNovemberVisible(!isVisible);
    }
  };

  const closeAllTabsFromNavBar = () => {
    setCloseAllTabs(true);
  };

  useEffect(() => {
    const isLogged = localStorage.getItem("@logged");
    
    if (isLogged) {
      setStatusLogged(true);
      setBlackNovember(isLogged ? true : false);
    }else{
      setStatusLogged(false);
    }
  }, [statusLogged]);

  return (
    <ScreenClassProvider>
      <ReactNotification />
      <GlobalStyles />
      <BrowserRouter>
        <Layout>
          <Navbar
            updateIsFullScreen={(e) => setIsFull(e)}
            isOpen={(e) => setIsOpen(e)}
            closeAllTabs={closeAllTabs}
            isClosed={() => setCloseAllTabs(!closeAllTabs)}
            updateIsMobile={(e) => setIsMobile(e)}
            routeConfig={routeConfig}
            isBlackNovember={isBlackNovemberVisible}
          />
          <Main
            isFull={isFull}
            isOpen={isOpen}
            onClick={() => closeAllTabsFromNavBar()}
          >

           { blackNovember === true ? (<BlackNovember onVisibilityChange={handleBlackNovemberVisibility}/>): (<></>)}
            <Routes
              isMobile={isMobile}
              isOpen={isOpen}
              upConfig={(e) => setRouteConfig(e)}
            />
          </Main>
        </Layout>
      </BrowserRouter>
    </ScreenClassProvider>
  );
};

export default App;
