import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
`;

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 2rem;

    gap: 1rem;
    display: flex;
    align-items: center;

    overflow-x: auto;

    ::-webkit-scrollbar {
        height: 0.1px;
    }
`;

export const CardNotification = styled.button`
    all: unset;

    height: auto;
    padding: 1rem;
    width: 26.25rem;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    border-radius: 1rem;
    background-color: rgba(255, 82, 82, 0.08);
`;

export const ImageAndNotification = styled.div`
    height: auto;
    width: 16.4375rem;

    display: flex;
    align-items: center;
`;

export const ContainerImage = styled.div`
    width: 3rem;
    height: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        width: 1rem;
    }
`;

export const Notification = styled.div`
    width: 100%;
    height: auto;
    max-width: 12.125rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > span {
        text-transform: uppercase;

        font-weight: 600;
        font-size: 0.5625rem;
        letter-spacing: 0.1rem;
        line-height: 0.84375rem;
        color: rgba(255, 82, 82, 1);
        font-family: "Lato", Sans-serif;
    }

    > p {
        text-transform: uppercase;

        font-weight: 800;
        font-size: 0.625rem;
        line-height: 0.9375rem;
        letter-spacing: 0.1rem;
        color: rgba(255, 82, 82, 1);
        font-family: "Lato", Sans-serif;
    }
`;

export const ButtonLocalization = styled.button`
    all: unset;

    height: auto;
    width: 6.5625rem;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;

    display: flex;
    align-items: center;

    border-radius: 10rem;
    background-color: #FFFFFF;

    > p {
        padding-left: 0.25rem;
        text-transform: uppercase;

        font-weight: 800;
        font-size: 0.5rem;
        letter-spacing: 0.1rem;
        line-height: 0.84375rem;
        color: rgba(255, 82, 82, 1);
        font-family: "Lato", Sans-serif;
    }
`;
