import styled from "styled-components";

export const Container = styled.div`
    width: 100%; 
`;

export const ContainerFormLogin = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem;

    gap: 1rem;
    display: flex;
    justify-content: center;

    border-radius: 1.5rem;
    background-color: #F8F8F9;

    @media (max-width: 1024px) {
        padding: 2rem;

        gap: 3rem;
    }

    @media (max-width: 767px) {
        padding: 0.5rem;
        
        align-items: center;
        flex-direction: column;
    }
`;

export const ContainerInfo = styled.div`
    width: 100%;
    height: auto;
    max-width: 37.75rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > p {
        font-family: "Lato", Sans-serif;
    }

    > p:nth-of-type(1) {
        margin-bottom: 1.5rem;

        color: #21262f;
        font-size: 2.5rem;
        font-weight: 800;
        line-height: 1.3em;
    }

    > p:nth-of-type(2) {
        height: 5.625rem;

        color: #303848;
        font-weight: 400;
        line-height: 1.6em;
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
    }

    > p:nth-of-type(3) {
        height: 4.9375rem;

        display: flex;
        align-items: flex-end;

        color: #586377;
        font-weight: 700;
        line-height: 1.5em;
        font-size: 0.625rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
    }

    @media (max-width: 1024px) {
        > p:nth-of-type(1) {
            font-size: 2.25rem;
            line-height: 1.3em;
        }

        > p:nth-of-type(2) {
            line-height: 1.6em;
            font-size: 1.125rem;
        }

        > p:nth-of-type(3) {
            line-height: 1.5em;
            font-size: 0.625rem;
        }
    }

    @media (max-width: 767px) {
        margin: 0;

        align-items: center;

        > p:nth-of-type(1) {
            font-size: 1.75rem;
            text-align: center;
        }

        > p:nth-of-type(2) {
            height: auto;

            margin: 0;
            text-align: center;
         }

        > p:nth-of-type(3) {
            height: 5.625rem;

            text-align: center;
            margin-bottom: 1.5rem;
        }
    }
`;

export const ContainerImage = styled.div`
    width: 100%;
    height: 6rem;

    margin-bottom: 1.5rem;
    
    > img {
        width: 8.75rem;
        height: auto;
    }

    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ContainerForm = styled.div`
    width: auto;
    padding: 0.5rem;
    height: 30.238rem;

    border-radius: 1rem;
    background-color: #F3F3F5;

    >iframe{
        width: 28rem;
        height: 100%;

        border-radius: 1rem;
        background-color: #FFF;

        @media (max-width: 1024px) {
            width: 25.125rem;
        }

        @media (max-width: 767px) {
            width: 100%;
        }

        @media (max-width: 479px) {    
            height: 28rem;
        }
    }

    >div {
        width: 28rem;
        height: 100%;

        border-radius: 1rem;
        background-color: #FFF;

        @media (max-width: 1024px) {
            width: 25.125rem;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    >div >div {
        width: auto;
        height: 100%;
    }

    >div >div > div {
        overflow-y: auto;

        width: auto;
        height: 100%;
        padding: 1rem;

        justify-content: flex-start;
        
        border-radius: 1rem;

        ::-webkit-scrollbar {
         height: 0.1px;
        }
    }

    >div >div >div >div {
        width: 100%;

        @media (max-width: 479px) {
            padding: 0;
        }

        @media (max-width: 340px) {
           >div:nth-of-type(5) {
                >label{
                    text-align: center;
                }
           }

           >div:nth-of-type(6) {
                padding: 0;
           }
        }
    }

    @media (max-width: 767px) {
        width: 100%;
    }

    @media (max-width: 479px) {
        height: auto;
    }
`;
