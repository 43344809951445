import React, { useState, useEffect } from "react";

import Input from "../../input/input_v2";
import Button from "../../button/button_v2";
import Select from "../../select/select_v2";
import api from "../../../services/api";
import { store } from "react-notifications-component";
import { Container, Row, Col, Hidden, ScreenClassRender } from "react-grid-system";
import { FormContainer, InfoCard, InfoCardWrapper, InfoCardPrevStepOne, InfoCardPrevStepTwo, Cards, Valores } from "./style";
import ModalTermos from '../../modal/modalTermos';
import ModalPoliticas from '../../modal/modalPoliticas';
import ModalFinalizar from "../../modal/modalText";
import validadorCpf from "../../../services/validadorCpf";
import validadorDeCpf from "../../../services/validadorCpf";
import ModalNew from "../../modal/modalGeneric";
import Radio from "../../radio";
import { FormCartao } from "../cartao";
export const storee = "@store";


const CompraStep3 = ({ ...props }) => {

    const {
        planoId,
        gridId,
        usuarios,
        documentos,
        receitas,
        valorTotal,
        valorUnitario,
        categoria,
        razaoSocial,
        nomeFantasia,
        cnpj,
        inscricaoEstadual,
        cep,
        logradouro,
        numero,
        bairro,
        complemento,
        cidade,
        estado,
        telefone,
        dataVencimento,
        responsavel,
        cargo,
        departamento,
        email,
        telefone2,
        numeroPedido,
        userEmail,
        prev,
        prevToOne,
        prevToTwo
    } = props

    const [nomeId, setNome] = useState('');
    const [cargoId, setCargo] = useState('');
    const [departamentoId, setDepartamento] = useState('');
    const [cpfId, setCpfId] = useState('');
    const [rgId, setRg] = useState('');
    const [emailId, setEmail] = useState('');

    const [cupom, setCoupon] = useState('');
    const [erroCoupon, setErroCoupon] = useState(false);
    const [erroCouponMessage, setErroCouponMessage] = useState('');
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [couponIsOk, setCouponIsOk] = useState(false);
    const [desconto, setDesconto] = useState("");

    const [disabled, setDisabled] = useState(true);
    const [cpfValido, setCpfValido] = useState(false);
    const [departamentoLabel, setDepartamentoLabel] = useState('');
    const [emailValidado, setEmailValidado] = useState(false);
    const [checkTermos, setCheckTermos] = useState(0);
    const [checkAcordo, setCheckAcordo] = useState(0);
    const [termos, setTermos] = useState(0);
    const [showTermos, setShowTermos] = useState(false);
    const [showPoliticas, setShowPoliticas] = useState(false);
    const [showText, setShowText] = useState(false);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);

    const [loadingCompra, setLoadingCompra] = useState(false);
    const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);

    //options
    const [opDepartamento, setOpDepartamento] = useState([]);
    const [erroCpf, setErroCpf] = useState(false);
    const [erroCpfMsg, setErroCpfMsg] = useState('');

    const [legendaDesconto, setLegendaDesconto] = useState('');


    const [qrCodePix, setQrCodePix] = useState('');
    const [pixCode, setPixCode] = useState('');
    const [showModalPix, setOpenModalPix] = useState(false);
    const [copiado, setCopiado] = useState(false);
    const [descontoPorCento, setDescontoPorCento] = useState(0);
    const [totalDesconto, setTotalDesconto] = useState(0);

    const [paymentMethodId, setPaymentMethodId] = useState(0);

    const [showCard, setShowCard] = useState(false);

    const [showViewEmail, setShowViewemail] = useState(false);
    const [payloadPix, setpayloadPix] = useState([]);
    const [planIdPix, setPlanIdPix] = useState('');
    const [dadosQueryString, setDadosQueryString] = useState('');

    const [paymentMethods, setPaymentMethods] = useState([
        { value: 1, label: 'BOLETO' },
        { value: 3, label: 'CARTÃO DE CRÉDITO' }
    ]);

    let digito = '';

    const getQueryParams = () => {
        const utm_id = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_id'));
        const utm_source = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_source'));
        const utm_medium = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_medium'));
        const utm_campaign = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_campaign'));
        const button = decodeURIComponent(new URLSearchParams(window.location.search).get('button'));

        const payload = {
            utm_id: utm_id && utm_id !== 'null' ? utm_id : 0,
            utm_source: utm_source && utm_source !== 'null' ? utm_source : 'orgânico',
            utm_medium: utm_medium && utm_medium !== 'null' ? utm_medium : 'orgânico',
            utm_campaign: utm_campaign && utm_campaign !== 'null' ? utm_campaign : 'orgânico',
            button: button && button !== 'null' ? button : 0

        }

        setDadosQueryString(payload);
    }

    const validateNome = async valor => {
        setNome(valor);
    }
    const validateCargo = async valor => {
        setCargo(valor);
    }
    const validateDepartamento = async valor => {
        setDepartamento(valor);
    }

    const validateCpf = cpfId => {
        setErroCpf(false);
        setCpfId(cpfId);
    };

    const validaCpf = (value) => {

        if (value) {
            const isValid = validadorDeCpf(value);

            setCpfValido(isValid);

            if (isValid) {
                setErroCpf(false);
            } else {
                setErroCpf(true);
                setErroCpfMsg('Informa um CPF válido!');
            }
        }
    }

    const validateRg = async valor => {
        setRg(valor);
    }



    const formatDepartamentos = async departamentos => {
        departamentos = await departamentos.map(dep => {

            if (dep.id === departamento) {
                setDepartamentoLabel(dep.nome);
            }

            const format = {
                value: dep.id,
                label: `${dep.nome}`
            };

            return format;
        });

        setOpDepartamento(departamentos);
    }

    const getDepartamentos = async () => {
        setLoadingDepartamentos(true);

        try {
            const response = await api.get(`/info/departamentos`);
            let departamentos = response.data.data;

            await formatDepartamentos(departamentos);

            setLoadingDepartamentos(false);

        } catch (error) {
            setLoadingDepartamentos(false);
        }
    }

    const openPaymentMethod = () => {
        setShowPaymentMethod(true);
    }

    const comprar = async (e, i) => {
        setShowCard(false);
        setLoadingCompra(true);

        if (props.isUpdatePlan === false) {

            localStorage.removeItem(storee);

            const payload = {
                tipo_compra: props.tipoCompra.value,
                cad_categoria: categoria,
                cad_razao_social: razaoSocial,
                cad_nome_fantasia: nomeFantasia,
                cad_cnpj: cnpj,
                cad_ie: inscricaoEstadual,
                cad_cep: cep,
                cad_endereco: logradouro,
                cad_numero: numero,
                cad_bairro: bairro,
                cad_complemento: complemento,
                cad_cidade: cidade,
                cad_estado: estado,
                cad_telefone: telefone,

                fat_data_vencimento: dataVencimento,
                fat_resp_cont_pagar: responsavel,
                fat_cargo: cargo,
                fat_departamento: departamento,
                fat_email_env_nfe: email,
                fat_telefone: telefone2,
                fat_num_ped_compra: numeroPedido,

                ass_resp_contr_nome_completo: nomeId,
                ass_resp_contr_cargo: cargoId,
                ass_resp_contr_departamento: departamentoId.value,
                ass_resp_contr_cpf: cpfId,
                ass_resp_contr_rg: rgId,
                ass_resp_contr_email: emailId,
                qtd_users: usuarios,
                qtd_documents: documentos,
                qtd_prescriptions: receitas,
                valor_total_plano: valorTotal,
                valor_unitario: valorUnitario,
                cupom: cupom ? couponIsOk === true ? cupom : '' : '',
                tipo_pagamento: paymentMethodId,
                termos,

                nome_cartao: e.nome_cartao || '',
                numero_cartao: e.num_cartao || '',
                expiracao_mes: e.expiracao_mes || '',
                expiracao_ano: e.expiracao_ano || '',
                ccv: e.ccv || '',
                nome_completo_cartao: e.nome_completo_cartao || '',
                email_cartao: e.email_cartao || '',
                cpf_cnpj_cartao: e.cpf_cnpj_cartao || '',
                cep_cartao: e.cep_cartao || '',
                endereco_cartao: e.endereco_cartao || '',
                complemento_cartao: e.complemento_cartao || '',
                numero_endereco_cartao: e.numero_endereco_cartao || '',
                fone_cartao: e.fone_cartao || '',
                cel_cartao: e.cel_cartao || '',
                salvar_cartao: 1
            }

            try {
                const response = await api.post(`/adquirir/plano/${planoId}/${gridId}`, payload);

                if (response.status === 200) {

                    const payloadTerm = {
                        username: userEmail,
                        type: 17,
                        termos
                    }

                    const responseTerm = await api.post(`/termos`, payloadTerm);

                    if (responseTerm.status === 200) {
                    }

                    setLoadingCompra(false);
                    setTimeout(() => {
                        window.location = '/compra/sucesso';
                    }, 200);
                }
            } catch (error) {
                setLoadingCompra(false);

                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            const payload3 = {
                nome: nomeId,
                cargo: cargoId,
                departamento: departamentoId.value,
                cpf: cpfId,
                rg: rgId,
                email: emailId,
                cupom: cupom ? couponIsOk === true ? cupom : '' : '',
                tipo_pagamento: parseInt(paymentMethodId),
                termos,

                nome_cartao: e.nome_cartao || '',
                numero_cartao: e.num_cartao || '',
                expiracao_mes: e.expiracao_mes || '',
                expiracao_ano: e.expiracao_ano || '',
                ccv: e.ccv || '',
                nome_completo_cartao: e.nome_completo_cartao || '',
                email_cartao: e.email_cartao || '',
                cpf_cnpj_cartao: e.cpf_cnpj_cartao || '',
                cep_cartao: e.cep_cartao || '',
                endereco_cartao: e.endereco_cartao || '',
                complemento_cartao: e.complemento_cartao || '',
                numero_endereco_cartao: e.numero_endereco_cartao || '',
                fone_cartao: e.fone_cartao || '',
                cel_cartao: e.cel_cartao || '',
                salvar_cartao: 1
            }
            props.updatePlan(payload3);

            setTimeout(() => {
                setLoadingCompra(false);
            }, 3000);
        }
    }

    const continuarCompra = () => {
        if (paymentMethodId === 3 || paymentMethodId === '3') {
            setShowPaymentMethod(false);
            setShowCard(true);

        } else if (paymentMethodId === 2 || paymentMethodId === '2') {
            // setShowPaymentMethod(false);
            // openPix()

            nextStep(2)
        } else {
            nextStep(1)
        }
    }

    let notificationShown = false;

    const validateEmail = (valor) => {
        setEmail(valor);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(valor);
       
        if (isValid) {
            setEmailValidado(true);
            notificationShown = false; 
        } else {
            setEmailValidado(false);
            notificationShown = true; 
        }
    };

    const validaEmail = () => {
        if (emailValidado === false && notificationShown === true) {
                store.addNotification({
                    title: "Erro!",
                    message: "Email inserido é inválido.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true,
                    },
                });
            }
    };

    const validateTermos = async (e) => {

        if (checkTermos === 0) {
            setCheckTermos(1);
        } else {
            setCheckTermos(0);
        }
    }

    const validateAcordo = async (e) => {

        if (checkAcordo === 0) {
            setCheckAcordo(1);
        } else {
            setCheckAcordo(0);
        }
    }

    const openTermos = async (e) => {
        setShowTermos(true);
    }

    const openPoliticas = async (e) => {
        setShowPoliticas(true);
    }

    const closeModal = () => {
        if (showTermos === true) {
            setShowTermos(false);
        }

        if (showPoliticas === true) {
            setShowPoliticas(false);
        }

        if (showText === true) {
            setShowText(false);
        }
    }

    const changeCoupon = (value) => {
        setCoupon(value);
        setErroCoupon(false);
    }

    const updateValues = (values) => {
        setNome(values.nome);
        setCargo(values.cargo);
        setDepartamento(values.contact_area_id)
        setCpfId(values.cpf);
        validaCpf(values.cpf);
        setRg(values.rg);
        setEmail(values.email);
        validaEmail(values.email);
    }

    const nextStep = async (tipoPagamento) => {
        setLoadingCompra(true);

        setShowPaymentMethod(false);
        setOpenModalPix(false);

        localStorage.removeItem(storee);

        const payload = {
            tipo_compra: props.tipoCompra.value,
            cad_categoria: categoria,
            cad_razao_social: razaoSocial,
            cad_nome_fantasia: nomeFantasia,
            cad_cnpj: cnpj,
            cad_ie: inscricaoEstadual,
            cad_cep: cep,
            cad_endereco: logradouro,
            cad_numero: numero,
            cad_bairro: bairro,
            cad_complemento: complemento,
            cad_cidade: cidade,
            cad_estado: estado,
            cad_telefone: telefone,

            fat_data_vencimento: dataVencimento,
            fat_resp_cont_pagar: responsavel,
            fat_cargo: cargo,
            fat_departamento: departamento,
            fat_email_env_nfe: email,
            fat_telefone: telefone2,
            fat_num_ped_compra: numeroPedido,

            ass_resp_contr_nome_completo: nomeId,
            ass_resp_contr_cargo: cargoId,
            ass_resp_contr_departamento: departamentoId.value,
            ass_resp_contr_cpf: cpfId,
            ass_resp_contr_rg: rgId,
            ass_resp_contr_email: emailId,
            qtd_users: usuarios,
            qtd_documents: documentos,
            qtd_prescriptions: receitas,
            valor_total_plano: valorTotal,
            valor_unitario: valorUnitario,
            cupom: cupom ? couponIsOk === true ? cupom : '' : '',
            termos: termos,
            tipo_pagamento: tipoPagamento,

            nome_cartao: '',
            numero_cartao: '',
            expiracao_mes: '',
            expiracao_ano: '',
            ccv: '',
            nome_completo_cartao: '',
            email_cartao: '',
            cpf_cnpj_cartao: '',
            cep_cartao: '',
            endereco_cartao: '',
            complemento_cartao: '',
            numero_endereco_cartao: '',
            fone_cartao: '',
            cel_cartao: '',
            salvar_cartao: 0,

            utm_source: dadosQueryString.utm_source,
            utm_medium: dadosQueryString.utm_medium,
            utm_id: dadosQueryString.utm_id,
            utm_campaign: dadosQueryString.utm_campaign,
            button: dadosQueryString.button
        }

        if (props.isUpdatePlan === false) {
            try {
                const response = await api.post(`/adquirir/plano/${planoId}/${gridId}`, payload);

                if (response.status === 200) {

                    const payloadTerm = {
                        username: userEmail,
                        type: 2,
                        termos
                    }

                    const responseTerm = await api.post(`/termos`, payloadTerm);

                    if (responseTerm.status === 200) {
                    }

                    if (tipoPagamento === 2 || tipoPagamento === '2') {
                        setpayloadPix(payload);
                        setPlanIdPix(planoId);

                        setShowViewemail(true);
                    } else {
                        setLoadingCompra(false);
                        setTimeout(() => {
                            window.location = '/compra/sucesso';
                        }, 200);
                    }
                }

            } catch (error) {
                setLoadingCompra(false);

                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });

            }
        } else {

            //porque a atualização volta para o index????
            const payload3 = {
                nome: nomeId,
                cargo: cargoId,
                departamento: departamentoId.value,
                cpf: cpfId,
                rg: rgId,
                email: emailId,
                cupom: cupom ? couponIsOk === true ? cupom : '' : '',
                tipo_pagamento: tipoPagamento,
                termos
            }
            props.updatePlan(payload3);
            setTimeout(() => {
                setLoadingCompra(false);
            }, 3000);
        }

    }

    const validaCoupon = async (e) => {

        try {
            if (e) {
                setLoadingCoupon(true);
                const response = await api.post(`/planos/validaCoupon`, {
                    cupom: e,
                    plano: planoId,
                    valor: valorTotal,
                    grid: gridId
                });
                const desconto = response.data.data.desconto;
                const calc = response.data.data.desconto_value;
                const total_desconto = response.data.data.valor_desconto;

                setDesconto(desconto);
                setDescontoPorCento(calc);
                setTotalDesconto(total_desconto);

                if (response) {
                    getPaymentMethods();
                    setCouponIsOk(true);
                }
                setLoadingCoupon(false);
            } else {
                setDesconto('');
                setDescontoPorCento(0);
                setCouponIsOk(false);
            }

        } catch (error) {
            setLoadingCoupon(false);
            setCouponIsOk(false);
            setErroCoupon(true);
            setErroCouponMessage(error.response.data.errors);

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }


    // const openPix = async () => {
    //     setShowPaymentMethod(false);

    //     try {

    //         const vlr = valorTotal.replace(',', '.');

    //         let total = parseFloat(vlr);
    //         let desc = ((total * descontoPorCento) / 100);

    //         total = total - desc;
    //         total = total.toFixed(2);

    //         const response = await api.get(`/gerarpix/${total}`);

    //         const qr = `data:image/png;base64, ${response.data.data.qrcode}`
    //         setQrCodePix(qr);
    //         setPixCode(response.data.data.codigo);

    //         setOpenModalPix(true);

    //     } catch (error) {

    //     }

    // }

    const copyPix = async (pixCode) => {
        await navigator.clipboard.writeText(pixCode);
        setCopiado(true);
    }

    const getPaymentMethods = async () => {
        try {
            if (cupom !== "") {
                setLoadingCoupon(true);
                const response = await api.post(`/pagamento/metodos/${planoId}`, {
                    cupom: cupom
                });

                if (response) {
                    setPaymentMethods(response.data.data);
                }
            } else {
            }

        } catch (error) {
            setPaymentMethods([
                { value: 1, label: 'BOLETO' },
                { value: 3, label: 'CARTÃO DE CRÉDITO' }
            ]);
        }
    }

    const changeMethodPayment = (e) => {
        setPaymentMethodId(e);
    }

    useEffect(() => {
        getDepartamentos();
        getQueryParams();
        updateValues(props.values);

        if (props.coupon && props.coupon !== '') {
            changeCoupon(props.coupon);
            validaCoupon(props.coupon);
        }
    }, [])

    useEffect(() => {
        if (copiado === true) {
            setTimeout(() => {
                setCopiado(false);
            }, 4000);
        }
    }, [copiado])

    useEffect(() => {
        setLegendaDesconto(desconto);

    }, [desconto])

    useEffect(() => {
        if (checkTermos === 1 && checkAcordo === 1) {
            setTermos(1);
        } else {
            setTermos(0);
        }

    }, [checkTermos, checkAcordo])

    useEffect(() => {
        if (nomeId && cargoId && departamentoId && cpfValido && emailValidado) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [nomeId, cargoId, departamentoId, cpfId, cpfValido, emailId, emailValidado])


    return (
        <>
            <ScreenClassRender
                render={screenClass => (
                    <FormContainer>
                        <Container>
                            <Row>
                                <Col>
                                    <form>
                                        <Input
                                            name="nome"
                                            id="nome"
                                            label="Nome completo"
                                            onChange={(e) => validateNome(e)}
                                            value={nomeId}
                                        />

                                        <Input
                                            name="cargo"
                                            id="cargo"
                                            label="Cargo"
                                            onChange={(e) => validateCargo(e)}
                                            value={cargoId}
                                        />

                                        <Select
                                            name="departamento"
                                            className="select"
                                            label="Departamento"
                                            id="departamento"
                                            value={departamentoId}
                                            onChange={(e) => validateDepartamento(e)}
                                            options={opDepartamento}
                                            loading={loadingDepartamentos}
                                            disabled={loadingDepartamentos}
                                        />

                                        <Input
                                            name="cpf"
                                            id="cpfId"
                                            label="CPF"
                                            inputmode="numeric"
                                            onChange={(e) => validateCpf(e)}
                                            onBlur={(e) => validaCpf(e.target.value)}
                                            value={cpfId}
                                            erro={erroCpf}
                                            messageErro={erroCpfMsg}
                                        />

                                        <Input
                                            name="rg"
                                            id="rg"
                                            label="RG"
                                            onChange={(e) => validateRg(e)}
                                            value={rgId}
                                        />

                                        <Input
                                            name="email"
                                            id="email"
                                            label="Email"
                                            type="email"
                                            onChange={(e) => validateEmail(e)}
                                            onBlur={() => validaEmail()}
                                            value={emailId}
                                        />

                                        <Input
                                            id="cupom"
                                            value={cupom}

                                            label="Cupom"
                                            onChange={(e) => changeCoupon(e)}
                                            erro={erroCoupon}
                                            legenda={legendaDesconto}
                                            messageErro={erroCouponMessage}
                                            marginBottom='0px'
                                            onBlur={(e) => validaCoupon(e.target.value)}
                                            loading={loadingCoupon}
                                            disabled={loadingCoupon}
                                            isValid={couponIsOk}
                                        />

                                        <div className="term">
                                            <input type="checkbox" id="termos" value="1" onChange={(e) => validateTermos(e)} />
                                            <label for="termos" className="lblTermos"> Li e concordo com os <label className="link" onClick={() => openTermos()}>Termos de uso</label> e a <label className="link" onClick={() => openPoliticas()}>Politica de Privacidade</label>  </label>
                                        </div>


                                        <div className="term">
                                            <input type="checkbox" id="acordo" value="1" onChange={(e) => validateAcordo(e)} />
                                            <label for="acordo" className="lblTermos"> Declaro que estou ciente e de acordo  </label>
                                        </div>

                                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                            <Button
                                                width='296px'
                                                height='50px'
                                                value='ESCOLHER FORMA DE PAGAMENTO'
                                                outline={false}
                                                disabled={disabled}
                                                onClick={() => openPaymentMethod()}
                                                loading={loadingCompra}
                                            />
                                        </div>

                                        <ModalTermos
                                            show={showTermos}
                                            btnOk={true}
                                            onHide={closeModal}
                                        ></ModalTermos>

                                        <ModalPoliticas
                                            show={showPoliticas}
                                            btnOk={true}
                                            onHide={closeModal}
                                        ></ModalPoliticas>
                                    </form>
                                </Col>


                                <Col md={6} sm={0} >
                                    <Hidden sm xm styles={{ display: "flex" }}>
                                        <InfoCardWrapper show={screenClass}>
                                            <Cards>
                                                <InfoCardPrevStepOne onClick={prevToOne}>
                                                    <h1>Dados do plano</h1>
                                                </InfoCardPrevStepOne>

                                                {props.isFree === false ? (
                                                    <InfoCardPrevStepTwo onClick={prevToTwo}>
                                                        <h1>Dados cadastrais</h1>
                                                    </InfoCardPrevStepTwo>
                                                ) : (null)}

                                                {props.isFree === false ? (
                                                    <InfoCard id="cardee" show={screenClass} onClick={prev}>
                                                        <h1>Dados de faturamento</h1>

                                                        <div className="item">
                                                            <small>Responsável pelo contas a pagar</small>
                                                            <p>{responsavel}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Departamento</small>
                                                            <p>{departamentoLabel}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Email</small>
                                                            <p className="p-estado">{email}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Telefone</small>
                                                            <p className="p-estado">{telefone2}</p>
                                                        </div>

                                                    </InfoCard>

                                                ) : (
                                                    <InfoCard id="cardee" show={screenClass} onClick={prevToTwo} style={{ marginTop: '-75px' }}>
                                                        <h1>Dados cadastrais</h1>

                                                        <div className="item">
                                                            <small>Categoria</small>
                                                            <p>{categoria}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Nome Fantasia</small>
                                                            <p>{nomeFantasia}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>CNPJ</small>
                                                            <p>{cnpj}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Logradouro</small>
                                                            <p className="p-estado">{logradouro}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Cidade</small>
                                                            <p className="p-estado">{cidade}</p>
                                                        </div>

                                                        <div className="item">
                                                            <small>Telefone</small>
                                                            <p className="p-estado">{telefone}</p>
                                                        </div>

                                                    </InfoCard>
                                                )}
                                            </Cards>
                                        </InfoCardWrapper>
                                    </Hidden>
                                </Col>
                            </Row>
                        </Container>
                    </FormContainer>
                )}
            />

            <ModalNew
                show={showPaymentMethod}
                width='40%'
                onHide={false}
            >
                <>
                    {
                        isNaN(parseFloat(valorTotal)) === false && (
                            <Valores>
                                <table>
                                    <tr>
                                        <td colspan="2" style={{ backgroundColor: '#f2f2f2' }}>
                                            <div> Resumo da compra</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total
                                        </td>
                                        <td className="direita">
                                            <div>{parseFloat(valorTotal.replace(',', '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                        </td>
                                    </tr>
                                    {
                                        cupom && cupom !== '' && couponIsOk && (
                                            <>
                                                <tr>
                                                    <td>
                                                        Cupom
                                                    </td>
                                                    <td className="direita">
                                                        <div className="desconto">- {(parseFloat(descontoPorCento)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Valor total
                                                    </td>
                                                    <td className="direita">
                                                        <div className="resultado">{parseFloat(totalDesconto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <div>{desconto}</div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                </table>
                            </Valores>
                        )
                    }

                    <h3 style={{ width: '100%', textAlign: 'center' }}>Modo de pagamento</h3>
                    <p style={{ width: '100%', textAlign: 'center', padding: '8px 0px 16px 0px' }}>Selecione a forma de pagamento.</p>


                    <div style={{
                        width: window.screen.width <= 991 ? '50%' : '40%',
                        margin: 'auto'
                    }}>
                        {planoId === 6 ? (
                            <Radio
                                options={[
                                    { value: 1, label: 'BOLETO' },
                                    { value: 2, label: 'PIX' },
                                    { value: 3, label: 'CARTÃO DE CRÉDITO' }
                                ]}
                                onChange={(e) => changeMethodPayment(e)}
                            />
                        ) : (
                            <>
                                <Radio
                                    options={paymentMethods}
                                    onChange={(e) => changeMethodPayment(e)}
                                />
                            </>
                        )}

                    </div>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '40px', marginBottom: '20px' }}>
                        <Button
                            width='40%'
                            height='40px'
                            value='CANCELAR'
                            red={true}
                            outline={true}
                            disabled={false}
                            onClick={() => setShowPaymentMethod(false)}
                            loading={false}
                        />

                        <Button
                            width='40%'
                            height='40px'
                            value='CONTINUAR'
                            outline={false}
                            disabled={false}
                            onClick={() => continuarCompra()}
                            loading={false}
                        />
                    </div>

                </>
            </ModalNew>

            <ModalNew
                show={showModalPix}
                onHide={() => {
                    setOpenModalPix(false);
                    setLoadingCompra(false);
                }}
                size='lg'
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h3 style={{
                        fontSize: '22px',
                        textAlign: 'center',
                        marginBottom: '30px'
                    }}>Leia o QRcode pelo aplicativo do seu banco</h3>
                    <img src={qrCodePix} alt="qrcode pix" style={{ margin: 'auto', marginBottom: '10px' }} />

                    <p
                        style={{ width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small' }}
                    > OU </p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <Button
                            width='35%'
                            value={copiado ? 'Código copiado!' : 'Copiar código PIX'}
                            outline={copiado ? false : true}
                            disabled={false}
                            onClick={() => copyPix(pixCode)}
                            loading={false}
                        />
                    </div>


                    <p style={{ width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small' }}> Após o pagamento, clique em <b>confirmar pagamento</b> para finalizar sua compra. </p>

                    <p style={{ width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small' }}> O acesso completo ao sistema estará liberado após a confirmação do pagamento.</p>

                </div>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <Button
                        width='40%'
                        height='50px'
                        value='CONFIRMAR PAGAMENTO'
                        outline={false}
                        disabled={false}
                        onClick={() => nextStep(2)}
                        loading={false}
                    />
                </div>
            </ModalNew>


            <ModalNew
                show={showCard}
                onHide={() => setShowCard(false)}
                size='lg'
            >
                <FormCartao
                    value={{
                        nome_completo_cartao: nomeId,
                        email_cartao: email,
                        cpf_cnpj_cartao: cnpj,
                        cep_cartao: cep,
                        numero_endereco_cartao: numero,
                        fone_cartao: telefone2
                    }}
                    callback={(e) => comprar(e, 'callback')}
                />
            </ModalNew>


            <ModalNew
                show={showViewEmail}
                size='md'
                onHide={false}
            >
                <>
                    <h3 style={{ width: '100%', textAlign: 'center' }}>Atenção!</h3>
                    <p style={{ width: '100%', textAlign: 'center' }}>Será enviado em seu email o PIX para o pagamento!</p>

                    <p style={{ width: '100%', textAlign: 'center' }}>Após a confirmação do pagamento sua conta será liberada automaticamente.</p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '40px', marginBottom: '20px' }}>

                        <Button
                            width='40%'
                            height='40px'
                            value='ENTENDI'
                            outline={false}
                            disabled={false}
                            onClick={() => window.location = '/compra/sucesso'}
                            loading={false}
                        />
                    </div>

                </>
            </ModalNew>


        </>
    );
};

export default CompraStep3;