import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../services/api';

import {
    Container,
    SessionRow,
    Card,
    FormContainer
} from '../../cadastro/style';

import Input from '../../../components/input/input_v2';
import Select from '../../../components/select/select_v2';
import Button from '../../../components/button/button_v2';

import ModalTermos from '../../../components/modal/modalTermos';
import ModalPoliticas from '../../../components/modal/modalPoliticas';

import { store } from "react-notifications-component";
import { login } from '../../../services/auth';

const CadastroStep2 = ({...props}) => {

    const [empresa, setEmpresa] = useState('');
    const [erroEmpresa, setErroEmpresa] = useState(false);
    const [erroEmpresaMessage] = useState('');
    
    const [telefone, setTelefone] = useState('');
    const [erroTelefone, setErroTelefone] = useState(false);
    const [erroTelefoneMessage] = useState('');

    const [cargo, setCargo] = useState('');
    const [cargos, setCargos] = useState([]);
    const [erroCargo, setErroCargo] = useState(false);
    const [loadingCargo, setLoadingCargo] = useState(false);

    const [showPolitica, setShowPolitica] = useState(false);
    const [showTermos, setShowTermos] = useState(false);

    const [receitas] = useState([
        {value: 'não', label: 'Não emito receitas'},
        {value: '1-10', label: '1 a 10'},
        {value: '11-30', label: '11 a 30'},
        {value: '31-50', label: '31 a 50'},
        {value: '51-100', label: '51 a 100'},
        {value: '+100', label: 'Mais de 100'}
    ]);
    const [receita, setReceita] = useState('');
    const [loadingReceita] = useState('');
    const [erroReceita, setErroReceita] = useState('');

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [emailGoogle, setEmailGoogle] = useState('');
    const [emailLinkedin, setEmailLinkedin] = useState('');

    const [utmParams, setUtmParams] = useState([]);
    const [redirectParams, setRedirectParams] = useState('');

    const [workspaces, setWorkspaces] = useState([]);
    const [workspace, setWorkspace] = useState([]);
    const [loadingWorkspace, setLoadingWorkspace] = useState(false);
    const [erroWorkspace, setErroWorkspace] = useState(false);

    const getJobsCategories = async () => {
        try {
            setLoadingCargo(true);

            const response = await api.get('/jobs/categories');

            if (response.data.data) {
                setCargos(response.data.data);
            }

            setLoadingCargo(false);
        } catch (error) {
            
        }
    }

    const getWorkspaces = async () => {
        try {
            setLoadingWorkspace(true);

            const response = await api.get('/workspaces');

            if (response.data.data) {
                setWorkspaces(response.data.data);
            }

            setLoadingWorkspace(false);
        } catch (error) {
            
        }
    }

    const changeEmpresa = (value) => {
        setEmpresa(value);
        setErroEmpresa(false);
    }
    
    const changeTelefone = (value) => {
        setTelefone(value);
        setErroTelefone(false);
    }

    const changeCargo = (value) => {
        setCargo(value);
        setErroCargo(false);
    }
    
    const changeWorkspace = (value) => {
        setWorkspace(value);
        setErroWorkspace(false);
    }
    
    const changeReceita = (value) => {
        setReceita(value);
        setErroReceita(false);
    }

    const clickPolitica = () => {
        setShowPolitica(true);
    }

    const clickTermos = () => {
        setShowTermos(true);
    }

    const signIn = async (email) => {
        try {

            try {
                const headers = {
                    'apiKey': process.env.REACT_APP_API_KEY
                }

                axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username: email, password: null, loginSocial: 1 }, { headers })
                .then(response => {
                    if (response) {
                        login(response.data.data, 'login');

                        let isLogged = localStorage.getItem('@logged');

                        if ((isLogged == false || isLogged == 'false') && (localStorage.getItem('@open') == false || localStorage.getItem('@open') == 'false')) {
                            localStorage.setItem("@open", "true");
                            window.location = '/apresentacao';
                        } else {
                            window.location = "/";
                        }
                    }
                }).catch(error => {
                    localStorage.removeItem('@agroreceita_google_email');
                    localStorage.removeItem('@agroreceita_google_user');
                    localStorage.removeItem('@agroreceita_google_user_profile');
                });
            } catch (err) {
                localStorage.removeItem('@agroreceita_google_email');
                localStorage.removeItem('@agroreceita_google_user');
                localStorage.removeItem('@agroreceita_google_user_profile');
            }
        } catch (err) {
            localStorage.removeItem('@agroreceita_google_email');
            localStorage.removeItem('@agroreceita_google_user');
            localStorage.removeItem('@agroreceita_google_user_profile');

        }
    }

    const handlePost = () => {
        setLoading(true);

        setTimeout(() => {
            if (props.step1Data?.id) {
                //CADASTRO COM O GOOGLE
                const payload = {
                    name: props.step1Data.name, 
                    email: props.step1Data.email,
                    id: props.step1Data.id,
                    nome_fantasia: empresa, 
                    telefone, 
                    cargo_id: cargo.value, 
                    cargo: cargo.label, 
                    workspace_id: workspace.value,
                    workspace: workspace.label,
                    receitas: receita.value,
                    utmParams: utmParams,
                    obs: emailLinkedin ? 'linkedin' : 'google'
                };
            
                const a = localStorage.getItem('@agroreceita_google_user_profile');
                let userProfile = JSON.parse(a);
        
                try {
                    api.post(`/cadastro/teste/google`, payload).then((response) => {
                        console.log(response)
                        localStorage.setItem("@agroreceita_email_sub", payload.email);
                        if (response) {

                            if (emailLinkedin) {
                                signIn(emailLinkedin, 'linkedin')
                            }else{
                                signIn(emailGoogle, 'google')
                            }
                        }
                    });
        
        
                } catch (error) {
                    setLoading(false);

                    if (error.response.status === 417) {
                        signIn(userProfile.email);
                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: `${error.response.data.errors}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            } else {
                //CADASTRO PREENCHENDO O FORMULÁRIO
                const payload = {
                    nome: props.step1Data.nome, 
                    email: props.step1Data.email,
                    nome_fantasia: empresa, 
                    telefone, 
                    cargo_id: cargo.value, 
                    cargo: cargo.label, 
                    workspace_id: workspace.value,
                    workspace: workspace.label,
                    receitas: receita.value,
                    utmParams: utmParams,
                    obs: 'email'
                };
    
                try {
                    api.post(`cadastro/teste`, payload).then((response) => {
                        if (response) {
                            localStorage.setItem("@agroreceita_useridentify", response.data.data.id );
                            localStorage.setItem("@agroreceita_email_sub", payload.email);
                            window.location = `/cadastro/sucesso${redirectParams}`
                        }
                    });
                } catch (error) {
                    setLoading(false);

                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        }, 300);


    }

    const checkSocialLogin = () => {
        setInterval(() => {
            const email = localStorage.getItem('@agroreceita_google_email');
            const emailSocial = localStorage.getItem('@agroreceita_linkedin_email');
            if (email && !emailGoogle) {
                setEmailGoogle(email);
            }else if(emailSocial && !emailLinkedin){
                setEmailLinkedin(emailSocial);
            }
        }, 300);
    }

    useEffect(() => {

        // setRedirectParams(props.history.location.search);
        setUtmParams(props.params);


        getJobsCategories();
        getWorkspaces();

        checkSocialLogin();

        setTimeout(() => {
            let aTags = document.getElementsByTagName("span");
            let searchText = "Login";
            let found;
    
            for (let i = 0; i < aTags.length; i++) {
                if (aTags[i].textContent == searchText) {
                    aTags[i].innerText = 'Continuar com Google';
                    break;
                }
            }
            setInterval(() => {
                let aTags = document.getElementsByTagName("span");
                let searchText = "Login";
                let found;
        
                for (let i = 0; i < aTags.length; i++) {
                    if (aTags[i].textContent == searchText) {
                        aTags[i].innerText = 'Continuar com Google';
                        break;
                    }
                }
            }, 500);
        }, 300);
    },[])

    useEffect(() => {
        if (empresa && telefone && cargo && receita) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    },[empresa, telefone, cargo, receita])


    return (
        <Container>
            <SessionRow>
                <Card backgroundColor='#fff' width='100vw'>
                    <FormContainer>

                        <Input 
                            id="empresa"
                            value={empresa}
                            label="Empresa"
                            onChange={(e) => changeEmpresa(e)}
                            erro={erroEmpresa}
                            messageErro={erroEmpresaMessage}
                            marginBottom='0px'
                        />

                        <Select
                            name="workspace"
                            className="workspace"
                            label="Local de trabalho"
                            id="workspace_id"
                            value={workspace.value || workspace}
                            isClearable={false}
                            onChange={c => changeWorkspace(c)}
                            options={workspaces}
                            isCompact={false}
                            isSearch={false}
                            selectUniqueOption={false}
                            isArrow={true}
                            loading={loadingWorkspace}
                            disabled={loadingWorkspace}
                            erro={erroWorkspace}
                            marginBottom='0px'
                        />

                        <Input 
                            id="telefone"
                            name="telefonecelular"
                            value={telefone}
                            label="Telefone/celular"
                            onChange={(e) => changeTelefone(e)}
                            erro={erroTelefone}
                            messageErro={erroTelefoneMessage}
                            marginBottom='0px'
                        />

                        <Select
                            name="cargo"
                            className="cargo"
                            label="Cargo"
                            id="cargo"
                            value={cargo.value || cargo}
                            isClearable={false}
                            onChange={c => changeCargo(c)}
                            options={cargos}
                            isCompact={false}
                            isSearch={false}
                            selectUniqueOption={false}
                            isArrow={true}
                            loading={loadingCargo}
                            disabled={loadingCargo}
                            erro={erroCargo}
                            marginBottom='0px'
                        />
                        
                        <Select
                            name="receitas"
                            className="receitas"
                            label="Quantas receitas você emite por mês?"
                            id="receitas"
                            value={receita.value || receita}
                            disabled={false}
                            isClearable={false}
                            onChange={r => changeReceita(r)}
                            options={receitas}
                            isCompact={false}
                            isSearch={false}
                            selectUniqueOption={false}
                            isArrow={true}
                            loading={loadingReceita}
                            erro={erroReceita}
                            marginBottom='0px'
                        />

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center', marginBottom: '15px' }}>
                            <Button 
                                width='139px'
                                height='48px'
                                value='Entrar'
                                outline={false}
                                disabled={disabled}
                                onClick={() => handlePost()}
                                loading={loading}
                            />
                        </div>

                        <div className='terms'>
                            <p>Ao continuar você concorda com a <label onClick={() => clickPolitica()}>Politica de Privacidade</label> e <label onClick={() => clickTermos()}>Termos de Uso</label></p>
                        </div>

                    </FormContainer>
                </Card>
            </SessionRow>


            <ModalTermos
                show={showTermos}
                btnOk={true}
                onHide={() => setShowTermos(false)}
            ></ModalTermos>

            <ModalPoliticas
                show={showPolitica}
                btnOk={true}
                onHide={() => setShowPolitica(false)}
            ></ModalPoliticas>
        </Container>
    )
}

export default CadastroStep2;